import React, { useState, useEffect } from 'react';

const RenamePDFModal = ({ show, handleClose, handleSave, currentFile }) => {
  const [newFileName, setNewFileName] = useState('');

  useEffect(() => {
    if (currentFile) {
      setNewFileName(currentFile.fileName);
    }
  }, [currentFile]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave(newFileName);
    handleClose();
  };

  useEffect(() => {
    if (show) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [show]);

  const resetForm = () => {
    setNewFileName(currentFile ? currentFile.fileName : '');
  };

  useEffect(() => {
    if (!show) {
      resetForm();
    }
  }, [show, currentFile]);

  return (
    <>
      {show && <div className="modal-backdrop fade show"></div>}
      <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="renamePDFModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 style={{color: "black"}} className="modal-title" id="renamePDFModalLabel">Rename PDF</h5>
              <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="newFileName">New File Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="newFileName"
                    value={newFileName}
                    onChange={(e) => setNewFileName(e.target.value)}
                    required
                  />
                </div>
                <br />
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={handleClose}>
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Rename PDF
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RenamePDFModal;
