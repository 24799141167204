import Header from "../components/Header";

export default function PageNotFound(){
    return(
        <>
            <Header />
            <div className="home-main-container">
                <div className="ErrorPage">
                    <h1>Error 404</h1>
                    <h2>Page Not Found</h2>
                    <p>You ventured beyond the limits of this platform. If you think this is a mistake, please contact the developers.</p>
                </div>
            </div>
        </>
    )
}