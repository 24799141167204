import React, { useState, useEffect } from 'react';

const MultiStepProgressBarRegistration = ({ steps, initialStep = 1 }) => {
  const [current, setCurrent] = useState(initialStep);

  useEffect(() => {
    setProgressBar(current);
  }, [current, steps.length]);

  const setProgressBar = (currentStep) => {
    const progressBar = document.querySelector('.multi-step-progress-bar');
    if (currentStep === steps.length) {
      progressBar.style.width = '100%';
      progressBar.style.display = 'none'; // Hide the progress bar on the last step
    } else {
      const percent = (100 / steps.length) * currentStep;
      progressBar.style.width = `${percent}%`;
      progressBar.style.display = 'block'; // Show the progress bar on other steps
    }
  };

  const nextStep = () => {
    if (current < steps.length) {
      setCurrent(current + 1);
    }
  };

  const previousStep = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const icons = [
    'fa-user-plus', // Sign up
    'fa-box', // Packages
    'fa-file-alt', // Registration
    'fa-credit-card', // Payment
    'fa-check', // Done
  ];

  return (
    <div className="multi-step container">
      <div className="row justify-content-center">
        <div className="multi-step-main-container">
          <div className="px-0 pt-4 pb-0 mt-3 mb-3">
            <form id="multi-step-form">
              <ul id="multi-step-progressbar">
                {steps.map((step, index) => (
                  <li key={index} className={current > index ? 'active' : ''} data-step={index + 1}>
                    <i className={`fas ${icons[index]}`}></i>
                    <strong>{step}</strong>
                  </li>
                ))}
              </ul>
              <div className="multi-step-progress">
                <div className="multi-step-progress-bar"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiStepProgressBarRegistration;
