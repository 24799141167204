import React from 'react';
import MultiStepProgressBar from '../components/MultiStepProgressBar';

export default function HelloWorld() {
    const stepsQA = ['Session 1', 'Session 2' ,'Session 3']
   
    return (
        <>
            <div className='gray-bg'>
            <h1 style={{color: "black"}}>Hello po, kamusta ka</h1>
            <MultiStepProgressBar 
                      steps={stepsQA} 
                      initialStep={1} 
                      isClientMode={true} 
                    />
            </div>
            
        </>
    );
}