import React, { useState, useEffect } from 'react';

const ReuploadPDFModal = ({ show, handleClose, handleSave, currentFile }) => {
  const [fileName, setFileName] = useState(currentFile ? currentFile.fileName : '');
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFileName(e.target.files[0]?.name || fileName);
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (file) {
      const formData = {
        fileName,
        file,
      };
      handleSave(formData);
      handleClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [show]);

  const resetForm = () => {
    setFileName(currentFile ? currentFile.fileName : '');
    setFile(null);
  };

  useEffect(() => {
    if (!show) {
      resetForm();
    }
  }, [show, currentFile]);

  return (
    <>
      {show && <div className="modal-backdrop fade show"></div>}
      <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="reuploadPDFModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 style={{color: "black"}} className="modal-title" id="reuploadPDFModalLabel">Reupload PDF</h5>
              <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label style={{color: "black"}}  htmlFor="formFile">Choose New PDF File</label>
                  <input
                    type="file"
                    className="form-control"
                    id="formFile"
                    accept="application/pdf"
                    onChange={handleFileChange}
                    required
                  />
                </div>
                <br />
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={handleClose}>
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Reupload PDF
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReuploadPDFModal;
