import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Footer from "../components/Footer";
import Header from "../components/Header";
import MultiStepProgressBarRegistration from '../components/MultiStepProgressBarRegistration';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { storage } from "../config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../config"; 
import Loader from "../pages/Loader"; 

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


export default function Registration({ userData, clearUserData }) {


    const [showModalView, setShowModalView  ] = useState(false);

    const handleShow = () => setShowModalView(true);
    const handleClose = () => setShowModalView(false);


    const { packagesid } = useParams();
    const navigate = useNavigate();
    const [currentFormStep, setCurrentFormStep] = useState(0); 
    const [currentStep, setCurrentStep] = useState(null); 
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        name: '',
        nickname: '',
        age: '',
        birthdate: '',
        address: '',
        phone: '',
        handler: '',
        email: '',
        first_schedule: '',
        image: null
    });
    const [errors, setErrors] = useState({});
    const [packageDetails, setPackageDetails] = useState(null);
    const [status, setStatus] = useState(""); 


    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', body: '' });

    const handleCloseModal = () => setShowModal(false);

    const getTomorrowDate = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        const year = tomorrow.getFullYear();
        const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
        const day = String(tomorrow.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };


    const checkUserInPaymentConfirmation = async (userId) => {
        try {
            const paymentDocRef = doc(db, "for_confirmation_payment", userId);
            const paymentDocSnap = await getDoc(paymentDocRef);
    
            if (paymentDocSnap.exists()) {
                return paymentDocSnap.data(); 
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching payment confirmation document: ", error);
            throw error; 
        }
    }

    useEffect(() => {
        AOS.init({ once: true });
        const fetchUserDataAndPackageDetails = async () => {
            try {
                if (userData) {
                    const userDocRef = doc(db, "users", userData.id);
                    const userDocSnap = await getDoc(userDocRef);
                    
                    if (userDocSnap.exists()) {
                        const userDocData = userDocSnap.data();
                        setFormData({
                            name: userDocData.name || '',
                            nickname: userDocData.nickname || '',
                            age: userDocData.age || '',
                            birthdate: userDocData.birthdate || '',
                            address: userDocData.address || '',
                            phone: userDocData.phone || '',
                            handler: userDocData.handler || '',
                            email: userDocData.email || '',
                            first_schedule: userDocData.first_schedule || '',
                            image: null
                        });
                        setCurrentStep(userDocData.registration_step || 0);
                    } else {
                        setCurrentStep(0);
                    }
                    setLoading(false);
                } else {
                    setCurrentStep(0);
                    setLoading(false);
                }

                const packageDocRef = doc(db, 'packages', 'K9VgkoMsGmu4Zu3wztv9');
                const packageDocSnap = await getDoc(packageDocRef);

                if (packageDocSnap.exists()) {
                    let realPackageName;
                    switch (packagesid) {
                        case 'package-w':
                            realPackageName = 'package_w';
                            break;
                        case 'package-o':
                            realPackageName = 'package_o';
                            break;
                        case 'package-r':
                            realPackageName = 'package_r';
                            break;
                        case 'package-d':
                            realPackageName = 'package_d';
                            break;
                        default:
                            realPackageName = null;
                    }

                    if (realPackageName) {
                        const packageData = packageDocSnap.data()[realPackageName];
                        if (packageData) {
                            setPackageDetails({
                                name: packageData[0],
                                description: packageData[1],
                                price: packageData[2]
                            });
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchUserDataAndPackageDetails();
    }, [userData, packagesid]);

    useEffect(() => {
        const checkPaymentConfirmation = async () => {
            if (userData) {
                try {
                    const paymentConfirmation = await checkUserInPaymentConfirmation(userData.id);
                    if (paymentConfirmation !== null) {
                        if (paymentConfirmation.status === 'confirmed') {
                            setCurrentFormStep(4);
                            setCurrentStep(5);
                            await updateDoc(doc(db, "users", userData.id), {
                                registration_step: 4,
                                currentPackage: packagesid
                            });
                            userData.registration_step = 4;
                            userData.currentPackage = packagesid;
                        } else if (paymentConfirmation.status === "rejected") {
                            setCurrentFormStep(3);
                            setStatus("rejected");
                        } else {
                            setCurrentFormStep(3);
                            setStatus("pending");
                            setCurrentStep(4);
                        }
                    }
                } catch (error) {
                    console.error("Error checking payment confirmation: ", error);
                }
            }
        };

        checkPaymentConfirmation();
    }, [userData, packagesid]);

    useEffect(() => {
        AOS.refresh();
    });

    useEffect(() => {
        if (!loading && (!userData || userData.currentPackage !== "NONE" )) {
            navigate('/packages-registration');
        }
    }, [loading, userData, navigate]);

    const steps = ['Sign up', 'Packages', 'Registration', 'Payment', 'Done'];

    const nextFormStep = () => {
        if (validateForm()) {
            setCurrentFormStep(prevStep => prevStep + 1);
        }
    }

    const prevFormStep = () => {
        setCurrentFormStep(prevStep => prevStep - 1);
    }

    const validateForm = () => {
        let valid = true;
        let errors = {};

        if (currentFormStep === 0) {
            if (!formData.name) {
                errors.name = "Full Name is required";
                valid = false;
            }
            if (!formData.nickname) {
                errors.nickname = "Nickname is required";
                valid = false;
            }
            if (!formData.age || formData.age <= 0) {
                errors.age = "Valid age is required";
                valid = false;
            }
            if (!formData.birthdate) {
                errors.birthdate = "Birthdate is required";
                valid = false;
            }
            if (!formData.address) {
                errors.address = "Address is required";
                valid = false;
            }
            if (!formData.phone) {
                errors.phone = "Phone number is required";
                valid = false;
            }
            if (!formData.handler) {
                errors.handler = "Handler's name is required";
                valid = false;
            }
        } else if (currentFormStep === 1) {
            if (!formData.email) {
                errors.email = "Email Address is required";
                valid = false;
            }
            if (!formData.first_schedule) {
                errors.first_schedule = "Preferred schedule is required";
                valid = false;
            }
        } else if (currentFormStep === 2) {
            if (!formData.image) {
                errors.image = "Image is required";
                valid = false;
            }
        }

        setErrors(errors);
        return valid;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData(prevFormData => ({
            ...prevFormData,
            image: file
        }));
    }

    const handleCancelRegistration = async () => {
        if (!userData) {
            return;
        }

        try {
            const paymentDocRef = doc(db, "for_confirmation_payment", userData.id);
            const paymentDocSnap = await getDoc(paymentDocRef);

            if (paymentDocSnap.exists() && paymentDocSnap.data().status === 'pending') {
                setModalContent({
                    title: 'Pending Payment Confirmation',
                    body: 'You have a pending payment confirmation. Please contact the admin to cancel your registration.'
                });
                setShowModal(true);
                return;
            }

            const userDocRef = doc(db, "users", userData.id);
            await updateDoc(userDocRef, {
                registration_step: 1,
                selected_package: ""
            });

            userData.selected_package = ""; 
            navigate('/packages-registration');
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    if (loading) {
        return <Loader />;
    }

    const submitRegistration = async () => {
        if (!userData) {
            console.log("no data");
            return;
        };

        const userDocRef = doc(db, "users", userData.id);
        const paymentDocRef = doc(db, "for_confirmation_payment", userData.id);

        try {
            if (currentFormStep === 2) {
                const { image, ...dataToUpdate } = formData; 
                dataToUpdate.registration_step = currentFormStep + 1;
                await updateDoc(userDocRef, dataToUpdate);
                console.log("Successfully updated users collection");
                if (formData.image) {
                    const storageRef = ref(storage, `payment_confirmation_images/${userData.id}`);
                    await uploadBytes(storageRef, formData.image);
                    const imageUrl = await getDownloadURL(storageRef);
                    const status = "pending";
                    const package_title = packagesid.replace('-', "_");
                    await setDoc(paymentDocRef, {
                        imageUrl,
                        userId: userData.id,
                        timestamp: new Date(),
                        status,
                        package_title: package_title
                    });
                    console.log("Successfully updated for_confirmation_payment collection");
                } else {
                    throw new Error("Image file is missing");
                }
            }
            setCurrentFormStep(prevStep => prevStep + 1);
            window.location.reload();
        } catch (error) {
            console.error("Error updating document: ", error);
        }

    }

    const additionalInfoNextForm = () => {
        if (validateForm()) {
            nextFormStep();
            if (!userData) {
                console.log("no data");
                return;
            }
            const userDocRef = doc(db, "users", userData.id);

            updateDoc(userDocRef, {
                registration_step: 3,
            }).then(() => {
                userData.registration_step = 3;
            }).catch(error => {
                console.error("Error updating document: ", error);
            });
        }
    }

    return (
        <>
            <Header userData={userData} clearUserData={clearUserData} />
            
            <div className="main-div-container bg-div ">
                <div>
                    <div className="main" data-aos="fade-up">
                        <img src="../../img/the-fortress-plain-logo.png" alt="The Fortress" />
                        <div className="head">
                            <p className="head_1">Training <span>Packages</span></p>
                        </div>
                        <div className="line-below-img"></div>

                        <div className="progress-tab-new">
                            {userData && userData.currentPackage === "NONE" && <MultiStepProgressBarRegistration steps={steps} initialStep={currentStep} />}
                        </div>
                    </div>

                    <div className='reg-process-container'>
                        {packageDetails && (
                            <div className="reg-process sentence">
                                <h2>{packageDetails.name}</h2>
                                <h5>{packageDetails.description}</h5>
                                <h3>{packageDetails.price}</h3>
                            </div>
                        )}
                        <div className='reg-process-cancel-container'>
                            <button className="client-btn client-btn-danger" onClick={handleCancelRegistration}>Cancel Registration</button>

                        </div>

                        <div class="container-fluid bg-div ">
                            <div class="row reg-row  justify-content-center bg-div ">
                                <div class='col-11 col-sm-9 col-md-7 col-lg-6 col-xl-9 text-center p-0 mt-3 mb-2 bg-div '>
                                    <div class='reg-card px-0 pt-4 pb-0 mt-3 mb-3'>
                                        <form class="msform bg-div   ">
                                            <h2 class="heading pb-4"> Registration Form </h2>
                                            {currentFormStep === 0 && (
                                                <fieldset data-aos="fade" data-aos-delay="100">
                                                    <div class="form-card">
                                                        <div class="row reg-row p-5">
                                                            <div class="col-7">
                                                                <h2 class="fs-title"> Personal Information: </h2>
                                                                <p> All Fields Required </p>
                                                            </div>
                                                            <div class="col-5">
                                                                <h2 className="reg-form-steps"> Step 1 - 4 </h2>
                                                            </div>

                                                            <label class="fieldlabels"> Name: * </label>
                                                            <input type="text" name="name" placeholder="Enter your Full Name" value={formData.name} onChange={handleInputChange} required />
                                                            {errors.name && <span className="error">{errors.name}</span>}
                                                            
                                                            <label class="fieldlabels"> Nickname: * </label>
                                                            <input type="text" name="nickname" placeholder="Enter your Nickname" value={formData.nickname} onChange={handleInputChange} required />
                                                            {errors.nickname && <span className="error">{errors.nickname}</span>}
                                                            
                                                            <div className="row reg-row">
                                                                <div class="col">
                                                                    <label class="fieldlabels"> Age: </label>
                                                                    <input type="number" name="age" placeholder="Enter your age" value={formData.age} onChange={handleInputChange} required />
                                                                    {errors.age && <span className="error">{errors.age}</span>}
                                                                </div>
                                                                <div class="col">
                                                                    <label class="fieldlabels"> Birthdate: </label>
                                                                    <input type="date" name="birthdate" placeholder="Enter birthdate" value={formData.birthdate} onChange={handleInputChange} required />
                                                                    {errors.birthdate && <span className="error">{errors.birthdate}</span>}
                                                                </div>
                                                            </div>

                                                            <label class="fieldlabels"> Address: * </label>
                                                            <input type="text" name="address" placeholder="Enter your address" value={formData.address} onChange={handleInputChange} required />
                                                            {errors.address && <span className="error">{errors.address}</span>}

                                                            <div className="row reg-row">
                                                                <div class="col">
                                                                    <label class="fieldlabels"> Contact number: * </label>
                                                                    <input type="phone" name="phone" placeholder="Enter Your Phone Number" value={formData.phone} onChange={handleInputChange} required />
                                                                    {errors.phone && <span className="error">{errors.phone}</span>}
                                                                </div>
                                                                <div class="col">
                                                                    <label class="fieldlabels"> Name of Handler: * </label>
                                                                    <input type="text" name="handler" placeholder="Enter your handler" value={formData.handler} onChange={handleInputChange} required />
                                                                    {errors.handler && <span className="error">{errors.handler}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input type="button" name="next" class="next action-button" value="Next" onClick={nextFormStep} />
                                                </fieldset>
                                            )}

                                            {currentFormStep === 1 && (
                                                <fieldset data-aos="fade" data-aos-delay="100">
                                                    <div class="form-card" >
                                                        <div class="row reg-row p-5">
                                                            <div class="col-7">
                                                                <h2 class="fs-title"> Additional Information: </h2>
                                                            </div>
                                                            <div class="col-5">
                                                                <h2 className="reg-form-steps"> Step 2 - 4 </h2>
                                                            </div>

                                                            <label class="fieldlabels"> Email Address: * </label>
                                                            <input type="email" name="email" placeholder="Enter your Email Address" value={formData.email} onChange={handleInputChange} required />
                                                            {errors.email && <span className="error">{errors.email}</span>}
                                                            
                                                            <label class="fieldlabels"> Preferred Schedule of First Session: * </label>
                                                            <input type="date" name="first_schedule" placeholder="Enter preferred date" value={formData.schedule} min={getTomorrowDate()} onChange={handleInputChange} required />
                                                            {errors.first_schedule && <span className="error">{errors.first_schedule}</span>}
                                                        </div>
                                                    </div>
                                                    <input type="button" name="next" class="next action-button" value="Next" onClick={additionalInfoNextForm}  />

                                                    <input type="button" name="previous" class="previous action-button" value="Previous" onClick={prevFormStep} />
                                                </fieldset>
                                            )}

                                            {currentFormStep === 2 && (
                                                 <fieldset>
                                                 <div class="form-card">
                                                     <div class="row reg-row p-5">
                                                         <div class="col-7">
                                                             <h2 class="fs-title"> Proof of Payment: </h2>
                                                         </div>
                                                         <div class="col-5">
                                                             <h2 className="reg-form-steps"> Step 3 - 4 </h2>
                                                         </div>

                                                         <div className="col">
                                                            <div className="row">
                                                                <div className="image-placeholder" onClick={handleShow} style={{ cursor: 'pointer' }}>
                                                                    <img src="../../img/payment_details.png" alt="Placeholder for uploaded image"  />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <label class="fieldlabels"> Upload your Image: * </label>
                                                                <input type="file" name="image" accept="image/*" onChange={handleImageChange} required />
                                                                {errors.image && <span className="error">{errors.image}</span>}
                                                            </div>
                                                         </div>
                                                     </div>
                                                 </div>
                                                 <input type="button" name="next" class="next action-button" value="Submit" onClick={submitRegistration} />

                                                 <input type="button" name="previous" class="previous action-button" value="Previous" onClick={prevFormStep} />
                                             </fieldset>
                                            )}
                                            {(currentFormStep === 3 && status==="pending")  && (
                                                <fieldset>
                                                    <div class="form-card">
                                                        <div class="row reg-row p-5">
                                                            <div class="col-7">
                                                                <h2 class="fs-title"> Pending Payment Confirmation </h2>
                                                            </div>
                                                            <div class="col-5">
                                                                <h2 className="reg-form-steps"> Step 4 - 5 </h2>
                                                            </div>
                                                            <div class="col-12 text-center">
                                                                <div class="icon-box">
                                                                    <i class="bi bi-check-circle-fill" style={{ fontSize: '12.0rem', color: '#3AB7FA'}}></i>
                                                                </div>
                                                                <p class="confirmation-message mt-3 text-center">Your registration is being processed. Please wait for confirmation.</p>
                                                                <p class="confirmation-message-note  text-center">If you think this is a mistake, please reload the page or contact the admin.</p>
                                                            </div>
                                                          
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            )}
                                            {(currentFormStep === 3 && status==="rejected")  && (
                                                <fieldset>
                                                    <div class="form-card">
                                                        <div class="row reg-row p-5">
                                                            <div class="col-7">
                                                                <h2 class="fs-title"> Payment Rejected </h2>
                                                            </div>
                                                            <div class="col-5">
                                                                <h2 className="reg-form-steps"> Step 4 - 5 </h2>
                                                            </div>
                                                            <div class="col-12 text-center">
                                                                <div class="icon-box">
                                                                    <i class="bi bi-x-circle-fill" style={{ fontSize: '12.0rem', color: '#FA3A3A' }}></i>
                                                                </div>
                                                                <div class="col-12 text-center mt-3">
                                                                    <p class="text-danger text-center">Your payment has been rejected. Please try again or contact support.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            )}
                                            {currentFormStep === 4 && (
                                                <fieldset>
                                                    <div class="form-card">
                                                        <div class="row reg-row p-5">
                                                            <div class="col-12 text-center">
                                                                <div class="icon-box">
                                                                    <i class="bi bi-check-circle-fill" style={{ fontSize: '12.0rem', color: '#3AB7FA'}}></i>
                                                                </div>
                                                                <p class="confirmation-message mt-3 text-center">Your payment has been confirmed. Thank you!</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalContent.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent.body}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showModalView} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>View Image Payment Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src="../../img/payment_details.png" alt="Placeholder for uploaded image" style={{ width: '100%' }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
