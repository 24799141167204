import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { db, storage } from "../../config"; 
import ConfirmModal from '../../components/ConfirmModal';
import SuccessMessageModal from '../../components/SuccessMessageModal';
import AddNewPDFModal from '../../components/LearningMaterialsComponents/AddNewPDFModal';
import ReuploadPDFModal from '../../components/LearningMaterialsComponents/ReuploadPDFModal';
import RenamePDFModal from '../../components/LearningMaterialsComponents/RenamePDFModal';

export default function LearningMaterialsControl() {
    const [pdfFiles, setPdfFiles] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showReuploadModal, setShowReuploadModal] = useState(false);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [confirmMessage, setConfirmMessage] = useState('');
    const [confirmAction, setConfirmAction] = useState(() => () => {});
    const [currentFile, setCurrentFile] = useState(null);

    useEffect(() => {
        retrieveFiles();
    }, []);

    const retrieveFiles = async () => {
        const filesSnapshot = await getDocs(collection(db, 'learning_materials'));
        const filesData = filesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPdfFiles(filesData);
    };

    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowReuploadModal = (file) => {
        setCurrentFile(file);
        setShowReuploadModal(true);
    };
    const handleCloseReuploadModal = () => setShowReuploadModal(false);

    const handleShowRenameModal = (file) => {
        setCurrentFile(file);
        setShowRenameModal(true);
    };
    const handleCloseRenameModal = () => setShowRenameModal(false);

    const handleAddNewFile = async (fileData) => {
        try {
            const storageRef = ref(storage, `learning_materials/${fileData.fileName}`);
            await uploadBytes(storageRef, fileData.file);
            const downloadURL = await getDownloadURL(storageRef);
    
            let imageURL = "https://firebasestorage.googleapis.com/v0/b/thefortresswebsite-d5c43.appspot.com/o/misc%2Flogo.jpg?alt=media&token=5186a951-e149-469e-a2c3-49aa091bb5f1";
            if (fileData.image) {
                const imageRef = ref(storage, `learning_materials/images/${fileData.image.name}`);
                await uploadBytes(imageRef, fileData.image);
                imageURL = await getDownloadURL(imageRef);
            }
    
            const docRef = await addDoc(collection(db, 'learning_materials'), {
                fileName: fileData.fileName,
                url: downloadURL,
                imageUrl: imageURL,
            });
    
            setPdfFiles([...pdfFiles, { id: docRef.id, fileName: fileData.fileName, url: downloadURL, imageUrl: imageURL }]);
            setSuccessMessage('PDF uploaded successfully!');
            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error adding document:', error);
        } finally {
            handleCloseAddModal();
        }
    };

    const handleDeleteFile = (id, fileName) => {
        setConfirmMessage('Are you sure you want to delete this PDF file?');
        setConfirmAction(() => () => confirmDeleteFile(id, fileName));
        setShowConfirmModal(true);
    };

    const confirmDeleteFile = async (id, fileName) => {
        try {
            const fileRef = ref(storage, `learning_materials/${fileName}`);
            await deleteObject(fileRef);
            await deleteDoc(doc(db, 'learning_materials', id));

            setPdfFiles(pdfFiles.filter(file => file.id !== id));
            setSuccessMessage('PDF deleted successfully!');
            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error deleting document:', error);
        } finally {
            setShowConfirmModal(false);
        }
    };

    const handleReuploadFile = async (fileData) => {
        try {
            const fileRef = ref(storage, `learning_materials/${currentFile.fileName}`);
            await deleteObject(fileRef);
            await uploadBytes(fileRef, fileData.file);
            const downloadURL = await getDownloadURL(fileRef);

            const fileDocRef = doc(db, 'learning_materials', currentFile.id);
            await updateDoc(fileDocRef, { url: downloadURL });

            setPdfFiles(pdfFiles.map(file => file.id === currentFile.id ? { ...file, url: downloadURL } : file));
            setSuccessMessage('PDF reuploaded successfully!');
            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error reuploading document:', error);
        } finally {
            handleCloseReuploadModal();
        }
    };

    const handleRenameFile = async (newFileName) => {
        try {
            const oldFileRef = ref(storage, `learning_materials/${currentFile.fileName}`);
            const newFileRef = ref(storage, `learning_materials/${newFileName}`);
    
            const fileBlob = await uploadBytes(oldFileRef);
    
            await uploadBytes(newFileRef, fileBlob);
    
            const newDownloadURL = await getDownloadURL(newFileRef);
    
            const fileDocRef = doc(db, 'learning_materials', currentFile.id);
            await updateDoc(fileDocRef, { fileName: newFileName, url: newDownloadURL });
    
            await deleteObject(oldFileRef);
    
            setPdfFiles(pdfFiles.map(file => file.id === currentFile.id ? { ...file, fileName: newFileName, url: newDownloadURL } : file));
            setSuccessMessage('PDF renamed successfully!');
            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error renaming document:', error);
        } finally {
            handleCloseRenameModal();
        }
    };

    return (
        <>
            <div className="headertitle">
                <h1>Learning Materials Management Panel</h1>
            </div>

            <hr className="my-4 main-hr" />

            <div className='scrollable-table'>
                <div className="container-fluid mt-5">
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover accomplish-table-custom text-center">
                            <thead className="thead-dark">
                                <tr>
                                    <th>No.</th>
                                    <th>Image</th>
                                    <th>File Name</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pdfFiles.map((file, index) => (
                                    <tr key={file.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <img 
                                                src={file.imageUrl} 
                                                alt={file.fileName} 
                                                style={{ width: '100px', height: 'auto', borderRadius: '8px' }} 
                                            />
                                        </td>
                                        <td>{file.fileName}</td>
                                        <td>
                                            <a href={file.url} target="_blank" rel="noopener noreferrer">
                                                <button className="btn btn-primary btn-sm m-2">Open PDF</button>
                                            </a>
                                            <button
                                                className="btn btn-danger btn-sm m-2"
                                                onClick={() => handleDeleteFile(file.id, file.fileName)}
                                            >
                                                Delete
                                            </button>
                                            <button
                                                className="btn btn-warning btn-sm m-2"
                                                onClick={() => handleShowReuploadModal(file)}
                                            >
                                                Reupload
                                            </button>
                                            <button
                                                className="btn btn-info btn-sm m-2"
                                                onClick={() => handleShowRenameModal(file)}
                                            >
                                                Rename
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <button className="btn btn-primary" onClick={handleShowAddModal}>Upload New PDF</button>
            </div>

            <AddNewPDFModal show={showAddModal} handleClose={handleCloseAddModal} handleSave={handleAddNewFile} />
            <ReuploadPDFModal show={showReuploadModal} handleClose={handleCloseReuploadModal} handleSave={handleReuploadFile} />
            <RenamePDFModal show={showRenameModal} handleClose={handleCloseRenameModal} handleSave={handleRenameFile} currentFile={currentFile} />

            <SuccessMessageModal
                show={showSuccessModal}
                handleClose={() => setShowSuccessModal(false)}
                title="Success"
                message={successMessage}
            />
            <ConfirmModal
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                title="Confirm Delete"
                message={confirmMessage}
                handleConfirm={confirmAction}
            />
        </>
    );
}
