import React, { useState, useEffect } from 'react';
import AddNewsModal from '../../components/NewsComponents/AddNewsModal';
import UploadProgressModal from '../../components/UploadProgressModal';
import UpdateNewsModal from '../../components/NewsComponents/UpdateNewsModal';
import SuccessMessageModal from '../../components/SuccessMessageModal';
import ConfirmModal from '../../components/ConfirmModal';
import MessageModal from '../../components/MessageModal'; 
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc, getDoc, query } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject, uploadBytes } from 'firebase/storage';
import { db, storage } from '../../config';

export default function News() {
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false); 
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentItem, setCurrentItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmAction, setConfirmAction] = useState(() => () => {});
  const [errorMessage, setErrorMessage] = useState(''); 
  const [recentWinTitle, setRecentWinTitle] = useState(''); 
  const [recentWinName, setRecentWinName] = useState(''); 
  const [previousWinTitle, setPreviousWinTitle] = useState(''); 
  const [recentWinDocId, setRecentWinDocId] = useState(null); 

  useEffect(() => {
    retrieveData();
    fetchRecentWinTitle(); 
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const filtered = data.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [searchQuery, data]);

  const retrieveData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'news'));
      const newsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setData(newsData);
      setFilteredData(newsData);
    } catch (error) {
      console.error('Error retrieving documents: ', error);
    }
  };

  const fetchRecentWinTitle = async () => {
    try {
      const q = query(collection(db, 'recent_news_title'));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const docData = doc.data();
        setPreviousWinTitle(docData.title);
        setRecentWinName(docData.name);
        setRecentWinDocId(doc.id);
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error fetching recent win title: ', error);
    }
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleProgressClose = () => {
    setShowProgressModal(false);
    setUploadProgress(0);
    setUploadSuccess(false);
  };

  const handleUpdateShow = (item) => {
    setCurrentItem(item);
    setShowUpdateModal(true);
  };

  const handleUpdateClose = () => {
    setCurrentItem(null);
    setShowUpdateModal(false);
  };

  const handleUpdate = async (updatedItem) => {
    console.log(`Update item with id: ${updatedItem.id}`);
    setShowProgressModal(true);
  
    try {
      const docRef = doc(db, 'news', updatedItem.id);
  
      const oldDoc = await getDoc(docRef);
      const oldImageURL = oldDoc.data().image;
  
      await updateDoc(docRef, {
        title: updatedItem.title,
        content: updatedItem.content,
        date: updatedItem.date,
        postUrl: updatedItem.postUrl
      });
   
      if (updatedItem.image) {
        const imageRef = ref(storage, `news-uploaded-images/${updatedItem.id}`);
        const uploadTask = uploadBytesResumable(imageRef, updatedItem.image);
  
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(Math.round(progress));
          },
          (error) => {
            console.error('Upload failed:', error);
            setErrorMessage(`Upload failed: ${error.message}`);
            setShowMessageModal(true);
            setShowProgressModal(false);
          },

          async () => {
            const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
            await updateDoc(doc(db, 'news', docRef.id), {
              image: imageURL,
            });

            setUploadSuccess(true);
            retrieveData();
            handleUpdateClose(); 

          }
         
        );
      } else {
        setErrorMessage('Image is required. Please upload an image.');
        setShowMessageModal(true);
        setShowProgressModal(false);
      }
  
      console.log('Document successfully updated!');
    } catch (error) {
      console.error('Error updating document:', error);
      setShowProgressModal(false);
    }
  };
  
  

  const handleDelete = (id) => {
    setConfirmMessage('Are you sure you want to delete this news item?');
    setConfirmAction(() => () => confirmDelete(id));
    setShowConfirmModal(true);
  };

  const confirmDelete = async (id) => {
    setShowConfirmModal(false);
    console.log(`Delete item with id: ${id}`);
    try {
      const docRef = doc(db, 'news', id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const docData = docSnapshot.data();
        const imageUrl = docData.image;

        if (imageUrl && !imageUrl.includes('https://firebasestorage.googleapis.com/v0/b/thefortresswebsite-d5c43.appspot.com/o/misc%2Flogo.jpg?alt=media&token=5186a951-e149-469e-a2c3-49aa091bb5f1')) {
          const imageRef = ref(storage, `news-uploaded-images/${id}`);
          try {
            await deleteObject(imageRef);
          } catch (error) {
            console.warn('Image not found or already deleted:', error);
          }
        }

        await deleteDoc(docRef);

        setSuccessMessage('Deleted Successfully!');
        setShowSuccessModal(true);
        retrieveData();
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const handleAddNew = async (formData) => {
    console.log('Form Data:', formData);
    if (!formData.image) {
      setErrorMessage('Image is required. Please upload an image.');
      setShowMessageModal(true);
      return;
    }
    setShowProgressModal(true);
    try {
      const { title, content, image, date, postUrl } = formData;

      const newsCollectionRef = collection(db, 'news');

      const docRef = await addDoc(newsCollectionRef, {
        title,
        content,
        date,
        postUrl,
      });

      if (image) {
        const imageRef = ref(storage, `news-uploaded-images/${docRef.id}`);
        const uploadTask = uploadBytesResumable(imageRef, image);
    
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(Math.round(progress));
          },
          (error) => {
            console.error('Upload failed:', error);
            setErrorMessage(`Upload failed: ${error.message}`); 
            setShowMessageModal(true); 
            setShowProgressModal(false);
          },
          async () => {
            const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
            await updateDoc(doc(db, 'news', docRef.id), {
              image: imageURL,
            });
            
            setUploadSuccess(true);
            retrieveData();
          }
        );
      }

      console.log('Document successfully written with image URL!');
    } catch (error) {
      console.error('Error writing document:', error);
      setShowProgressModal(false);
    }
  };

  const handleRecentWinTitleConfirm = async () => {
    try {
      if (recentWinDocId) {
        const docRef = doc(db, 'recent_news_title', recentWinDocId);
        await updateDoc(docRef, {
          title: recentWinTitle,
          name: recentWinName,
        });
        console.log('Recent Win Title and Name updated:', recentWinTitle, recentWinName);
        fetchRecentWinTitle();
      } else {
        const newDocRef = await addDoc(collection(db, 'recent_news_title'), {
          title: recentWinTitle,
          name: recentWinName,
        });
        setRecentWinDocId(newDocRef.id);
        fetchRecentWinTitle();
      }
    } catch (error) {
      console.error('Error updating recent win title:', error);
    }
  };

  return (
    <>
      <div className="headertitle">
        <h1>News Management</h1>
      </div>

      <hr className="my-4 main-hr" />

      <div className="card container mt-5 p-4">
        <h2 style={{color: "black", fontWeight: "bold", fontSize: "2.5rem"}}>Recent Win Header</h2>
        <div className="input-group mb-3 ">
          <input
            type="text"
            style={{color: "#71b7e6", fontWeight: "bold", fontSize: "1.5rem"}}
            className="form-control m-2 p-3"
            placeholder="Enter recent win title..."
            onChange={(e) => setRecentWinTitle(e.target.value)}
          />
          
          <input
            type="text"
            style={{color: "#71b7e6", fontWeight: "bold", fontSize: "1.5rem"}}
            className="form-control m-2 p-3"
            placeholder="Enter name..."
            onChange={(e) => setRecentWinName(e.target.value)}
          />
          <button className="btn btn-primary m-2" onClick={handleRecentWinTitleConfirm}>Confirm</button>
        </div>
        {previousWinTitle && recentWinName && (
          <div className="mt-3">
            <h5 style={{color: "black"}}>Current Win Title:</h5>
            <p style={{color: "#71b7e6", fontWeight: "bold", fontSize: "2.0rem"}}>{previousWinTitle}</p>

            <h5 style={{color: "black"}}>Current Win Name:</h5>
            <p style={{color: "#71b7e6", fontWeight: "bold", fontSize: "2.0rem"}}>{recentWinName}</p>
          </div>
        )}
      </div>
      <hr className="my-4 main-hr" />

      <div className='scrollable-table-news'>
        <div className="container-fluid mt-5">
          <div className="mb-4">
            <input
              type="text"
              className="form-control"
              placeholder="Search by title..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-hover accomplish-table-custom text-center">
              <thead className="thead-dark">
                <tr>
                  <th>No.</th>
                  <th>Title</th>
                  <th>Content</th>
                  <th>Image</th>
                  <th>Date</th>
                  <th>POST URL</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td className="news-data-style">{item.title}</td>
                    <td className="news-data-style">{item.content}</td>
                    <td>
                      <img src={item.image} alt="content" className="img-fluid" style={{ width: '100px', height: 'auto' }} />
                    </td>
                    <td className="news-data-style">{item.date}</td>
                    <td className="news-data-style">{item.postUrl}</td>
                    <td>
                      <button className="m-2 btn btn-warning btn-sm me-2 mb-1" onClick={() => handleUpdateShow(item)}>Update</button>
                      <button className="m-2 btn btn-danger btn-sm mb-1" onClick={() => handleDelete(item.id, item.title)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>


        <div className="mt-4">
          <button className="btn btn-primary" onClick={handleShow}>Add New News</button>
          <AddNewsModal show={showModal} handleClose={handleClose} handleSave={handleAddNew} />
          <UpdateNewsModal show={showUpdateModal} handleClose={handleUpdateClose} handleSave={handleUpdate} item={currentItem} />
        </div>

      </div>

      <UploadProgressModal
        show={showProgressModal}
        progress={uploadProgress}
        success={uploadSuccess}
        handleClose={handleProgressClose}
      />
      <SuccessMessageModal
        show={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        title="Success"
        message={successMessage}
      />
      <ConfirmModal
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        title="Confirm Delete"
        message={confirmMessage}
        handleConfirm={confirmAction}
      />
      <MessageModal
        show={showMessageModal}
        handleClose={() => setShowMessageModal(false)}
        title="Error"
        message={errorMessage}
      />
    </>
  );
}
