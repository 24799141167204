import React, { useState, useEffect } from 'react';
import Footer from "../components/Footer";
import Header from "../components/Header";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../config";
import { useNavigate } from 'react-router-dom'; 
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function LearningMaterials({ userData }) {
    const [materials, setMaterials] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate(); 

    useEffect(() => {
        AOS.init({ once: true });
    }, []);

    useEffect(() => {
        AOS.refresh();
    });

    useEffect(() => {
        const checkUserRegistration = async () => {
            if (userData && userData.id) {
                try {
                    const q = query(collection(db, 'registered_trainees'), where('userId', '==', userData.id));
                    const querySnapshot = await getDocs(q);
                    
                    if (querySnapshot.empty) {
                        alert('You are not enrolled. Redirecting to home.');
                        navigate('/home');
                        return; 
                    }
                } catch (error) {
                    console.error('Error checking user registration:', error);
                    alert('An error occurred. Please try again.');
                    navigate('/home');
                    return;
                }
            }else if(userData.type == "ADMIN_USER"){
                    return;
            
            } else {
                alert('User data is missing. Redirecting to home.');
                navigate('/home');
                return;
            }
            
        };

        checkUserRegistration();

        const fetchMaterials = async () => {
            const materialsSnapshot = await getDocs(collection(db, 'learning_materials'));
            const materialsData = materialsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setMaterials(materialsData);
        };

        fetchMaterials();
    }, [userData, navigate]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredMaterials = materials.filter(material => 
        material.fileName.toLowerCase().includes(searchTerm)
    );

    return (
        <>
            <Header userData={userData} />
            <div className="learning-material-container main-div-container" data-aos="fade-up" data-aos-delay="100">
                <h1 className="section-title"> <span>Learning</span> Materials</h1>
                
                <div className="d-flex justify-content-center mb-3 p-4">  
                    <div style={{ display: 'flex', alignItems: 'center', width: '70%' }}>  
                        <h1 style={{color: "white", marginRight: '18px'}} >Search</h1>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Filename..."
                            aria-label="Search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            style={{
                                borderRadius: '50px',
                                padding: '0.75rem 1.5rem',
                                fontSize: '2.0rem',
                                border: '1px solid #ddd',
                                width: '100%',
                                backgroundColor: '#e0e0e0'
                            }}
                        />
                    </div>
                </div>

                <div className="materials-grid">
                    {filteredMaterials.length > 0 ? (
                        filteredMaterials.map((material, index) => (
                            <div className="material-card" key={material.id} data-aos="fade-up" data-aos-delay={`${100 + (index * 50)}`}>
                                <div className="material-card-content">
                                    <img src={material.imageUrl} alt={material.fileName} className="material-image" />
                                    <h3 className="material-title">{material.fileName}</h3>
                                    <a href={material.url} target="_blank" rel="noopener noreferrer" className="material-link">
                                        <button className="btn btn-primary">Open PDF</button>
                                    </a>
                                </div>
                            </div>
                        ))
                    ) : (
                        
                        <div style={{ gridColumn: '1 / -1', textAlign: 'center' }}>  {/* Spans across all columns */}
                            <h1 style={{ margin: 'auto', color: "white" }}>No learning materials found.</h1>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
}
