import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Testimonial({ userData, clearUserData }) {
  const { id } = useParams();
  const [testimonial, setTestimonial] = useState(null);

  useEffect(() => {
    const fetchTestimonial = async () => {
      try {
        const docRef = doc(db, 'members', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setTestimonial(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching testimonial: ', error);
      }
    };

    fetchTestimonial();
  }, [id]);

  return (
    <>
      <Header userData={userData} clearUserData={clearUserData} />
   
      <div className="testimonial-container main-div-container">
        <div className="testimonial-div-container">
          {testimonial ? (
            <div className="testimonial-content">
              <div className="testimonial-image-column">
                {testimonial.image && (
                  <img
                    src={testimonial.image}
                    alt={`${testimonial.name}'s profile`}
                    className="testimonial-image"
                  />
                )}
              </div>
              <div className="testimonial-main-container">
                <h1 className="testimonial-title">Testimonial</h1>
                <hr className="testimonial-hr" />

                <div className="testimonial-text-column">
                
                  <div className='card testimonial-card'>
                    <p className="testimonial-text">{testimonial.testimonials}</p>
                    <div className="testimonial-details">
                      <h2>{testimonial.name}</h2>
                      {testimonial.titles && testimonial.titles.split(',').map((title, index) => (
                        <p key={index} className="testimonial-title-item">{title.trim()}</p>
                      ))}
                    </div>
                  </div> 
                </div>

              </div>
              

              

            </div>
          ) : (
            <p>Loading testimonial...</p>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}
