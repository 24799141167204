export default function Footer() {
    return (
        <>
        <footer class="footer">
            <div class="footer_container">
                <div class="footer_row">
                    <div class="footer-col">
                        <h4>Company</h4>
                        <ul class="list_items">
                            <li><a href="/home">Home</a></li>
                            <li><a href="/about">About us</a></li>
                            <li><a href="/packages-registration">Packages</a></li>
                        </ul>
                    </div>
                    <div class="footer-col">
                        <h4>Get help</h4>
                        <ul class="list_items">
                            <li><a href="/about#faq">FAQ's</a></li>
                        </ul>
                    </div>
                    <div class="footer-col">
                        <h4>Get in Touch</h4>
                        <div class="contactInfo">
                            <div class="iconGroup">
                                <div class="icon">
                                    <i class="fa fa-envelope"></i>
                                </div>
                                <div class="details">
                                    <span>Email</span>
                                    <span>thefortressphilippines@gmail.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-col">
                        <h4>follow us</h4>
                        <div class="social-links">
                            <a href="https://www.facebook.com/thefortressofficial"><i class="fab fa-facebook-f"></i></a>
                        </div>
                    </div>
                    <div class="footer-copyright">
                        <p>&copy; 2024 The Fortress. All rights reserved.</p>
                    </div>
                    <div class="footer-developers">
                        <p>Website developed by Leam Arevalo And Christian Serwelas</p>
                        <p className="webversion">Website Version 1.1.0a</p>
                    </div>
                    
                </div>
            </div>
        </footer>
            
        </>
    )
}