import React, { useState, useEffect } from 'react';

export default function Testimonials() {

    const handleUpdate = (id) => {
        console.log(`Update item with id: ${id}`);
    };

    const handleDelete = (id) => {
        console.log(`Delete item with id: ${id}`);
    };
    const handleAddNew = () => {
        console.log('Add new accomplishment');
    };

    const data = [];

    return (
        <>
            <div className="headertitle">
                <h1>Testimonials Management</h1>
            </div>

            <hr className="my-4 main-hr" />
            
            <div className="container-fluid mt-5">
                <div className="mb-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search by title..."
                    />
                </div>

                <div className="table-responsive">
                    <table className="table table-bordered table-hover accomplish-table-custom text-center">
                        <thead className="thead-dark">
                            <tr>
                                <th>No.</th>
                                <th>Client Email</th>
                                <th>Title</th>
                                <th>Review</th>
                                <th>Image</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={item.id}>
                                    <td>{index + 1}</td>
                                    <td className="testimonial-data-style">{item.uploader}</td>
                                    <td className="testimonial-data-style">{item.title}</td>
                                    <td className="testimonial-data-style">{item.review}</td>
                                    <td>
                                        <img src={item.image} alt="content" className="img-fluid" style={{ width: '100px', height: 'auto' }} />
                                    </td>
                                    <td className="testimonial-data-style">{item.date}</td>
                                    <td>
                                        <button className="m-2 btn btn-warning btn-sm me-2 mb-1" onClick={() => handleUpdate(item.id)}>Update</button>
                                        <button className="m-2 btn btn-danger btn-sm mb-1" onClick={() => handleDelete(item.id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
