import React, { useEffect, useState } from "react";
import Typed from 'typed.js';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc, collection, query, where, getDocs, getDoc } from 'firebase/firestore';
import Footer from "../components/Footer";
import Header from "../components/Header";
import MultiStepProgressBar from '../components/MultiStepProgressBar';
import { db } from "../config"; 
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Loader from "../pages/Loader"; 
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button
import ConfirmModal from '../components/ConfirmModal'; // Import ConfirmModal

export default function Client({ clearUserData, setUserData }) {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  useEffect(() => {
      AOS.refresh();
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [userData, setUserDataState] = useState(null);
  const [editedUserData, setEditedUserData] = useState(null);
  const [packageDetails, setPackageDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userPackageReading, setUserPackageReading] = useState([0, 0]); // Default to [0, 0]
  const [showTestimonialModal, setShowTestimonialModal] = useState(false); // New state for testimonial modal visibility
  const [showLogoutModal, setShowLogoutModal] = useState(false); // New state for logout modal visibility
  const [showEditSaveModal, setShowEditSaveModal] = useState(false); // New state for edit save modal visibility

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUserData = JSON.parse(localStorage.getItem('userData'));
      console.log("Stored user data: ", storedUserData);
      if (storedUserData && storedUserData.email) {
        try {
          const q = query(collection(db, 'users'), where('email', '==', storedUserData.email));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userData = { id: userDoc.id, ...userDoc.data() };
            console.log("Fetched user data: ", userData);

            setUserDataState(userData);
            setEditedUserData(userData);
            setImagePreview(userData.image || null);

            let realPackageName;
            if (userData.currentPackage && userData.currentPackage !== "NONE") {
              const packageDocRef = doc(db, 'packages', 'K9VgkoMsGmu4Zu3wztv9');
              const packageDocSnap = await getDoc(packageDocRef);
              
              switch (userData.currentPackage) {
                case 'package-w':
                case 'package_w':
                  realPackageName = 'package_w';
                  break;
                case 'package-o':
                case 'package_o':
                  realPackageName = 'package_o';
                  break;
                case 'package-r':
                case 'package_r':
                  realPackageName = 'package_r';
                  break;
                case 'package-d':
                case 'package_d':
                  realPackageName = 'package_d';
                  break;
                default:
                  realPackageName = null;
              }

              if (packageDocSnap.exists()) {
                const packageData = packageDocSnap.data()[realPackageName];
                console.log("Fetched package data: ", packageData);
                setPackageDetails({
                  stepsQA: Array.from({ length: parseInt(packageData[3]) }, (_, i) => `Session ${i + 1}`),
                  stepsAssessments: Array.from({ length: parseInt(packageData[4]) }, (_, i) => `Session ${i + 1}`)
                });
              }
            }
          } else {
            throw new Error('User not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          clearUserData();
          localStorage.removeItem('userData');
          navigate('/login');
        }
      }
      setLoading(false); // Set loading to false after data is fetched
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const getPackagesSessionDetails = async () => {
      try {
        if (userData) {
          const packageDocRef = doc(db, 'registered_trainees', userData.id);
          const packageDocSnap = await getDoc(packageDocRef);
          if (packageDocSnap.exists()) {
            const packageData = packageDocSnap.data();
            console.log("packageData");

            console.log(packageData);
            setUserPackageReading([packageData["assessment_took"] || 0, packageData["session_took"] || 0]);
          }
        }
      } catch (error) {
        console.error('Error fetching user package session details:', error);
      }
    };

    getPackagesSessionDetails();
  }, [userData]);

  useEffect(() => {
    if (userData && userData.image) {
      setImagePreview(userData?.image);
    }
  }, [userData]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const storage = getStorage();

    try {
      const userId = userData.id; 
      const storageRef = ref(storage, `users/${userId}/${file.name}`);

      const snapshot = await uploadBytes(storageRef, file);
      console.log('File uploaded successfully:', snapshot);

      const downloadURL = await getDownloadURL(snapshot.ref);
      console.log('File available at:', downloadURL);

      await updateUserData(userId, { image: downloadURL });
      setImagePreview(downloadURL); 
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const updateUserData = async (userId, dataToUpdate) => {
    const userRef = doc(db, 'users', userId);
    try {
      await updateDoc(userRef, dataToUpdate);
      console.log('User data updated successfully');

      const updatedUserData = { ...userData, ...dataToUpdate };
      setUserDataState(updatedUserData); 
      setEditedUserData(updatedUserData); 
      localStorage.setItem('userData', JSON.stringify(updatedUserData));
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  const handleEditSave = async () => {
    setShowEditSaveModal(false); // Hide the modal
    await updateUserData(userData.id, editedUserData);
    setEditMode(false); 
  };

  const handleEditCancel = () => {
    setEditedUserData(userData); 
    setEditMode(false); 
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUserData({ ...editedUserData, [name]: value });
  };

  useEffect(() => {
    const selectTyped = document.querySelector('.typed');
    if (selectTyped) {
      let typed_strings = selectTyped.getAttribute('data-typed-items');
      typed_strings = typed_strings.split(',');
      new Typed('.typed', {
        strings: typed_strings,
        loop: true,
        typeSpeed: 100,
        backSpeed: 50,
        backDelay: 2000
      });
    }
  }, []);

  const handleWriteTestimonialClick = (userData) => {
    navigate(`/writetestimonial/${userData.id}`);
  };
    const handleLearningMaterialClick = async () => {
      navigate('/learningmaterials');

  };
  const handleEnrollAgain = () => {
    navigate(`/packages-registration`);
  }

  const handleCloseModal = () => {
    setShowTestimonialModal(false); 
  }

  const handleShowLogoutModal = () => {
    setShowLogoutModal(true);
  }

  const handleConfirmLogout = () => {
    handleLogout();
    setShowLogoutModal(false); 
  }

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false); 
  }

  const handleShowEditSaveModal = () => {
    setShowEditSaveModal(true);
  }

  const handleConfirmEditSave = () => {
    handleEditSave();
    setShowEditSaveModal(false);
  }

  const handleCloseEditSaveModal = () => {
    setShowEditSaveModal(false);
  }

  const handleLogout = () => {
    clearUserData();
    localStorage.removeItem('userData');
    navigate('/login');
  };

  if (loading) { 
    return <Loader />;
  }
  return (
    <>
      <Header userData={userData} clearUserData={clearUserData} />

      <section className="section-client">
        <div className="client-page home-main-container">
          <div>
            <div className="main"></div>
          </div>

          <div className="client-page-container">
            <div className="pic_container">
              <div className="avatar-preview" data-aos="fade-up" style={{ backgroundImage: `url(${imagePreview || userData?.image || '../../img/the-fortress-logo-with-tagline.png'})` }}>
              </div>
              <div className="avatar-edit">
                <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" onChange={handleImageUpload} />
                <label htmlFor="imageUpload">
                  <i className="fas fa-pencil-alt"></i>
                </label>
              </div>
            </div>

            <div className="head" data-aos="fade-up">
              <p className="client-header">Welcome, <span>{userData.name}</span></p>
            </div>
          </div>

          <div className="client-description container mt-4 trainee-details-container" data-aos="fade-up" data-aos-delay="100">
            {editMode ? (
              <div className="client-edit">
                <div className="client-details-row">
                  <div className="client-details-pair">
                    <p>Name </p><input type="text" name="name" value={editedUserData.name} onChange={handleInputChange} className="client-input" placeholder="Name" />
                    <p>Age </p><input type="text" name="age" value={editedUserData.age} onChange={handleInputChange} className="client-input" placeholder="Age" />
                  </div>
                  <div className="client-details-pair">
                    <p>Address </p><input type="text" name="address" value={editedUserData.address} onChange={handleInputChange} className="client-input" placeholder="Address" />
                    <p>Contact Number </p><input type="text" name="contactNumber" value={editedUserData.contactNumber} onChange={handleInputChange} className="client-input" placeholder="Contact Number" />
                  </div>
                  <div className="client-details-pair">
                    <p>Birthdate</p><input type="text" name="birthdate" value={editedUserData.birthdate} onChange={handleInputChange} className="client-input" placeholder="Birthdate" />
                    <p>Handler Name</p> <input type="text" name="handlerName" value={editedUserData.handlerName} onChange={handleInputChange} className="client-input" placeholder="Handler Name" />
                  </div>
                </div>
                <button className="client-btn client-edit-buttons" onClick={handleShowEditSaveModal}>Save</button>
                <button className="client-btn client-btn-danger client-edit-buttons" onClick={handleEditCancel}>Cancel</button>
              </div>
            ) : (
              <div>
                <div className="client-details-row">
                  <div className="client-details-pair">
                    <p><strong>Name:</strong> <span>{userData.name || 'N/A'}</span></p>
                    <p><strong>Age:</strong> <span>{userData.age || 'N/A'}</span></p>
                  </div>
                  <div className="client-details-pair">
                    <p><strong>Address:</strong> <span>{userData.address || 'N/A'}</span></p>
                    <p><strong>Contact:</strong> <span>{userData.phone || 'N/A'}</span></p>
                  </div>
                  <div className="client-details-pair">
                    <p><strong>Birthday:</strong> <span>{userData.birthdate || 'N/A'}</span></p>
                    <p><strong>Name of Handler:</strong> <span>{userData.handler || 'N/A'}</span></p>
                  </div>
                </div>
                {
                  userData 
                  && 
                  userData.currentPackage === "NONE" 
                  && 
                  !userData.age 
                  && 
                  !userData.birthdate 
                  && 
                  !userData.handlerName 
                  && 
                  !userData.address 
                  && 
                  !userData.contactNumber 
                  ? 
                  <p className="client-reg-completion">To complete your registration, please go to <a href="/packages-registration">Packages/Registration</a> Page</p>
                  :
                  <button className="client-btn client-edit-buttons" onClick={() => setEditMode(true)}>Edit</button>
                }
              </div>
            )}
          </div>
        </div>
        
        {userData && userData.currentPackage !== "NONE" ? (
          <div data-aos="fade-up" data-aos-delay="100">
            <div className="client-page-scale-down trainee-session-card card mt-5" style={{marginTop: '8rem'}}>
              <div className="trainee-session-card-body card-body">
                <div>
                  <h2 className="trainee-session-headers session-package-title">{userData.currentPackage}</h2>
                  <h3 className="trainee-session-headers">Q & A / Communications Coaching Sessions : <span style={{ color: '#3AB7FA' }}>SESSIONS</span></h3>
                  {packageDetails && userPackageReading && (
                    <MultiStepProgressBar 
                      steps={packageDetails.stepsQA} 
                      initialStep={parseInt(userPackageReading[1]) || 0} 
                      isClientMode={true} 
                    />
                  )}
                </div>
              
                <div>
                  <h3 className="trainee-session-headers">ASSESSMENTS <span style={{ color: '#3AB7FA' }}>SESSIONS</span></h3>
                  {packageDetails && userPackageReading && (
                    <MultiStepProgressBar 
                      steps={packageDetails.stepsAssessments} 
                      initialStep={parseInt(userPackageReading[0]) || 0} 
                      isClientMode={true} 
                    />
                  )}
                </div>
                <input type="button" className="client-btn enroll-btn" value="Enroll Again" onClick={handleEnrollAgain}/> 
                <input type="button" className="client-btn testimonial-btn" value="Write Testimonial" onClick={() => handleWriteTestimonialClick(userData)}/>

                
                <input type="button" className="client-btn testimonial-btn" value="Learning Materials" onClick={handleLearningMaterialClick}/>
                <button onClick={handleShowLogoutModal} className="client-btn client-btn-danger">
                  Logout
                </button>
              </div>
            </div>
          </div> 
        ) : (
          <div data-aos="fade-up" data-aos-delay="100">
            <div className="client-page-scale-down trainee-session-card card mt-5">
              <div className="trainee-session-card-body card-body">
                <div>
                  {userData.currentPackage === "NONE" && (
                        <div>
                            <h2 className="trainee-session-headers session-package-title text-center">You currently have no package</h2>
                          </div>
                  )}  
                </div>
                
                <button onClick={handleShowLogoutModal} className="client-btn client-btn-danger">
                  Logout
                </button>
                <input type="button" className="client-btn testimonial-btn" value="Write Testimonial" onClick={() => handleWriteTestimonialClick(userData)}/>


              </div>
            </div>
          </div>
        )}
      </section>

      <Modal show={showTestimonialModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Feature Under Construction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This feature is still under construction. Please check back later.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmModal 
        show={showLogoutModal} 
        handleClose={handleCloseLogoutModal} 
        title="Confirm Logout" 
        message="Are you sure you want to logout?" 
        handleConfirm={handleConfirmLogout} 
      />

      <ConfirmModal 
        show={showEditSaveModal} 
        handleClose={handleCloseEditSaveModal} 
        title="Confirm Save" 
        message="Are you sure you want to save these changes?" 
        handleConfirm={handleConfirmEditSave} 
      />
      
      <Footer />
    </>
  );
}
