import React, { useState, useEffect } from 'react';
import AddNewAccomplishmentModal from '../../components/AccomplishmentComponents/AddNewAccomplishmentModal';
import UpdateAccomplishmentModal from '../../components/AccomplishmentComponents/UpdateAccomplishmentModal';
import SuccessMessageModal from '../../components/SuccessMessageModal';
import ConfirmModal from '../../components/ConfirmModal';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../config';

export default function Accomplishments() {
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentItem, setCurrentItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmAction, setConfirmAction] = useState(() => () => {});

  useEffect(() => {
    retrieveData();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const filtered = data.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [searchQuery, data]);

  const retrieveData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'accomplishments'));
      const accomplishmentsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setData(accomplishmentsData);
      setFilteredData(accomplishmentsData);
    } catch (error) {
      console.error('Error retrieving documents: ', error);
    }
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleUpdateShow = (item) => {
    setCurrentItem(item);
    setShowUpdateModal(true);
  };

  const handleUpdateClose = () => {
    setCurrentItem(null);
    setShowUpdateModal(false);
  };

  const handleUpdate = async (updatedItem) => {
    console.log(`Update item with id: ${updatedItem.id}`);
    try {
      const docRef = doc(db, 'accomplishments', updatedItem.id);

      await updateDoc(docRef, {
        title: updatedItem.title,
        content: updatedItem.content,
        date: updatedItem.date,
      });

      setSuccessMessage('Updated Successfully!');
      setShowSuccessModal(true);
      retrieveData();
    } catch (error) {
      console.error('Error updating document: ', error);
    } finally {
      handleUpdateClose();
    }
  };

  const handleDelete = (id) => {
    setConfirmMessage('Are you sure you want to delete this accomplishment?');
    setConfirmAction(() => () => confirmDelete(id));
    setShowConfirmModal(true);
  };

  const confirmDelete = async (id) => {
    setShowConfirmModal(false);
    console.log(`Delete item with id: ${id}`);
    try {
      await deleteDoc(doc(db, 'accomplishments', id));

      setSuccessMessage('Deleted Successfully!');
      setShowSuccessModal(true);
      retrieveData();
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };

  const handleAddNew = async (formData) => {
    console.log('Form Data:', formData);
    try {
      const docRef = await addDoc(collection(db, 'accomplishments'), formData);
      console.log('Document written with ID: ', docRef.id);
      setShowModal(false);
      setData([...data, { id: docRef.id, ...formData }]);
      setSuccessMessage('Added Successfully!');
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <>
      <div className="headertitle">
        <h1>Accomplishments Management</h1>
      </div>

      <hr className="my-4 main-hr" />
      <div className='scrollable-table'>
        <div className="container-fluid mt-5">
          <div className="mb-4">
            <input
              type="text"
              className="form-control"
              placeholder="Search by title..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-hover accomplish-table-custom text-center">
              <thead className="thead-dark">
                <tr>
                  <th>No.</th>
                  <th>Title</th>
                  <th>Content</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td className="accomplish-data-style">{item.title}</td>
                    <td className="accomplish-data-style">{item.content}</td>
                    <td className="accomplish-data-style">{item.date}</td>
                    <td>
                      <button className="m-2 btn btn-warning btn-sm me-2 mb-1" onClick={() => handleUpdateShow(item)}>Update</button>
                      <button className="m-2 btn btn-danger btn-sm mb-1" onClick={() => handleDelete(item.id)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        </div>
      <div className="mt-4">
        <button className="btn btn-primary" onClick={handleShow}>Add New Accomplishment</button>
        <AddNewAccomplishmentModal show={showModal} handleClose={handleClose} handleSave={handleAddNew} />
        <UpdateAccomplishmentModal show={showUpdateModal} handleClose={handleUpdateClose} handleSave={handleUpdate} item={currentItem} />
      </div>
      <SuccessMessageModal
        show={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        title="Success"
        message={successMessage}
      />
      <ConfirmModal
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        title="Confirm Delete"
        message={confirmMessage}
        handleConfirm={confirmAction}
      />
    </>
  );
}
