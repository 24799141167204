import Footer from "../components/Footer";
import Header from "../components/Header";
import MultiStepProgressBarRegistration from '../components/MultiStepProgressBarRegistration';
import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../config"; 
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from "../pages/Loader"; 

export default function PackagesRegistration({ userData, clearUserData, setUserData }) {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(null); 
    const [fetchPackage, setFetchPackage] = useState(null); 
    const [packages, setPackages] = useState([]);
    const [currentPackage, setCurrentPackage] = useState(""); 
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        AOS.init({ once: true });

        if (userData) {
            if (userData.type !== "ADMIN_USER") {
                refetchUserData(userData.id);
            }
        } else {
            setCurrentStep(0);
            setLoading(false); 
        }

    }, [userData]);

    useEffect(() => {
        AOS.refresh();
        fetchPackages();
    }, []);

    const refetchUserData = async (userId) => {
        const userDocRef = doc(db, "users", userId);
        try {
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                const userDocData = userDocSnap.data();
                setUserData({ id: userDocSnap.id, ...userDocData });
                setCurrentStep(userDocData.registration_step || 0);
                setFetchPackage(userDocData.selected_package);
                setCurrentPackage(userDocData.currentPackage);
            } else {
                setCurrentStep(0);
            }
        } catch (error) {
            console.error("Error fetching document: ", error);
            setCurrentStep(0);
        }
        setLoading(false); 
    };

    const fetchPackages = async () => {
        try {
            const packagesSnapshot = await getDocs(collection(db, 'packages'));
            const packagesData = packagesSnapshot.docs.flatMap(doc => {
                const data = doc.data();
                return Object.entries(data).map(([key, value]) => ({
                    key,
                    name: value[0],
                    description: value[1],
                    price: value[2]
                }));
            });
            const order = ['package_w', 'package_o', 'package_r', 'package_d'];
            const sortedPackages = packagesData.sort((a, b) => order.indexOf(a.key) - order.indexOf(b.key));

            setPackages(sortedPackages);
        } catch (error) {
            console.error("Error fetching packages: ", error);
        }
    };

    const steps = ['Sign up', 'Packages', 'Registration', 'Payment', 'Done'];

    const handleBookPackage = (packageName) => {
        if (!userData) {
            navigate(`/login#sign-up-mode`);
            return;
        }
        if (currentPackage !== "NONE") {
            setShowModal(true);
            return;
        }
        if (userData) {
            if (userData.type !== "ADMIN_USER") {
                const userDocRef = doc(db, "users", userData.id);
        
                updateDoc(userDocRef, {
                    registration_step: 2,
                    selected_package: packageName
                }).then(() => {
                    userData.registration_step = 2;
                    userData.selected_package = packageName;
                    console.log(`Package ${packageName} booked successfully`);
                }).catch(error => {
                    console.error("Error updating document: ", error);
                });
        
                navigate(`/registration/${packageName}`);
               }
            else {
                setLoading(false); 
            }
        } else {
            navigate(`/login`);
        }
    };
    useEffect(() => {
        console.log(fetchPackage);
        if(userData){
            if(userData.type !== "ADMIN_USER"){
                if(userData.currentPackage !== "NONE"){
                    return;
                }
                if (typeof userData.selected_package !== "undefined") {
                    if(fetchPackage === null) {
                        navigate(`/packages-registration`);
    
                    }  else  if(fetchPackage == ""){
                        console.log("fetchPackage undefined");
                        navigate(`/packages-registration`);
                    }
                    else {
                        navigate(`/registration/${fetchPackage}`);
                    }
                } 
            }
            else {
                setLoading(false); 
            }
        }
    }, []);
    if (userData) {
        if (userData.type !== "ADMIN_USER") {
            
            if (currentStep === 0) {
                navigate(`/login`);
            }
            if (currentStep === null) {
                return <Loader />;
            } 
            if (currentStep > 2 && currentStep < 3) {
                if(fetchPackage !== "null") {
                    navigate(`/registration/${fetchPackage}`);

                } else {
                    navigate(`/packages-registration`);
                    
                }
            }
        }
    }
    
    const handleCloseModal = () => {
        setShowModal(false);
    };

    if (loading) { 
        return <Loader />;
    }

    return (
        <>
            <Header userData={userData} clearUserData={clearUserData} />
            <div className="packages-container main-div-container">
                <div>
                    <div className="main" data-aos="fade-up">
                        <img src="../../img/the-fortress-plain-logo.png" alt="The Fortress" />
                        <div className="head">
                            <p className="head_1">Training <span>Packages</span></p>
                        </div>
                        <div className="line-below-img"></div>

                        <div className="progress-tab-new">
                            {
                                userData 
                                && 
                                currentPackage === "NONE" 
                                && 
                                !userData.age 
                                && 
                                !userData.birthdate 
                                && 
                                !userData.handlerName 
                                && 
                                !userData.address 
                                && 
                                !userData.contactNumber 
                                &&
                                <MultiStepProgressBarRegistration steps={steps} initialStep={currentStep} />
                            }
                        </div>
                        
                    </div>
                    <div className="sentence">
                        <h3 data-aos="fade-up" data-aos-delay="100">
                            Unlock your strength at The Fortress with our exclusive training packages.
                            Rise, speak, reign as you elevate your skills and conquer the stage!
                            Book now and become a brilliant public speaker!
                            Feel free to send us a message for your inquiries about our offers. Thank you!
                        </h3>
                        <h1 data-aos="fade-up" data-aos-delay="200">Select Your Package</h1>
                    </div>
                    <section className="neon bd-container" data-aos="fade-up" data-aos-delay="300">
                        <div className="neon__container">
                            {packages.map((pkg, index) => (
                                <div className="neon__card" key={index}>
                                    <h1 className="neon__title">{pkg.name}</h1>
                                    <p className="neon__description">{pkg.description}</p>
                                    <p className="neon__price">PHP {pkg.price}</p>
                                    <a href={`/registration/${pkg.name.toLowerCase().replace(' ', '-')}`} className="neon__button" onClick={(e) => { e.preventDefault(); handleBookPackage(pkg.name.toLowerCase().replace(' ', '-')); }}>Book</a>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{userData?.type === "ADMIN_USER" ? "Admin Privileges" : "Current Package Ongoing"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {userData?.type === "ADMIN_USER" ? "Admin Cannot Book." : "You already have a current package ongoing. Please complete it before booking a new one."}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
