import React, { useState, useEffect } from 'react';
import AddWinsModal from '../../components/WinsComponents/AddWinsModal';
import UploadProgressModal from '../../components/UploadProgressModal';
import UpdateWinsModal from '../../components/WinsComponents/UpdateWinsModal';
import SuccessMessageModal from '../../components/SuccessMessageModal';
import ConfirmModal from '../../components/ConfirmModal';
import MessageModal from '../../components/MessageModal';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject, uploadBytes } from 'firebase/storage';
import { db, storage } from '../../config';

export default function Wins() {
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentItem, setCurrentItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmAction, setConfirmAction] = useState(() => () => {});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    retrieveData();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const filtered = data.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [searchQuery, data]);

  const retrieveData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'wins'));
      const winsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setData(winsData);
      setFilteredData(winsData);
    } catch (error) {
      console.error('Error retrieving documents: ', error);
    }
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleProgressClose = () => {
    setShowProgressModal(false);
    setUploadProgress(0);
    setUploadSuccess(false);
  };

  const handleUpdateShow = (item) => {
    setCurrentItem(item);
    setShowUpdateModal(true);
  };

  const handleUpdateClose = () => {
    setCurrentItem(null);
    setShowUpdateModal(false);
  };

  const handleUpdate = async (updatedItem) => {
    console.log(`Update item with id: ${updatedItem.id}`);
    setShowProgressModal(true);
    try {
      const docRef = doc(db, 'wins', updatedItem.id);

      const oldDoc = await getDoc(docRef);
      const oldTitle = oldDoc.data().title;

      await updateDoc(docRef, {
        title: updatedItem.title,
        content: updatedItem.content,
        date: new Date().toISOString().split('T')[0],
      });

      if (updatedItem.image) {
        const imageRef = ref(storage, `wins-uploaded-images/${updatedItem.id}`);
        const uploadTask = uploadBytesResumable(imageRef, updatedItem.image);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(Math.round(progress));
          },
          (error) => {
            console.error('Upload failed:', error);
            setErrorMessage(`Upload failed: ${error.message}`); // Set error message
            setShowMessageModal(true); // Show error message modal
            setShowProgressModal(false);
          },

          //here

          

          async () => {
            const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
            await updateDoc(doc(db, 'wins', docRef.id), {
              image: imageURL,
            });

            

            setUploadSuccess(true);
            retrieveData();
          }
        );
      } else {
        setErrorMessage('Image is required. Please upload an image.'); 
        setShowMessageModal(true);
        setShowProgressModal(false);
      }

      console.log('Document successfully updated!');
    } catch (error) {
      console.error('Error updating document:', error);
      setShowProgressModal(false);
    } finally {
      handleUpdateClose();
    }
  };

  const handleDelete = (id) => {
    setConfirmMessage('Are you sure you want to delete this wins item?');
    setConfirmAction(() => () => confirmDelete(id));
    setShowConfirmModal(true);
  };

  const confirmDelete = async (id) => {
    setShowConfirmModal(false);
    console.log(`Delete item with id: ${id}`);
    try {
      const docRef = doc(db, 'wins', id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const docData = docSnapshot.data();
        const imageUrl = docData.image;

        if (imageUrl && !imageUrl.includes('https://firebasestorage.googleapis.com/v0/b/thefortresswebsite-d5c43.appspot.com/o/misc%2Flogo.jpg?alt=media&token=5186a951-e149-469e-a2c3-49aa091bb5f1')) {
          const imageRef = ref(storage, `wins-uploaded-images/${id}`);
          try {
            await deleteObject(imageRef);
          } catch (error) {
            console.warn('Image not found or already deleted:', error);
          }
        }

        await deleteDoc(docRef);

        setSuccessMessage('Deleted Successfully!');
        setShowSuccessModal(true);
        retrieveData();
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const handleAddNew = async (formData) => {
    console.log('Form Data:', formData);
    if (!formData.image) {
      setErrorMessage('Image is required. Please upload an image.');
      setShowMessageModal(true);
      return;
    }
    setShowProgressModal(true);
    try {
      const { title, content, image, date, postUrl } = formData;

      const winsCollectionRef = collection(db, 'wins');

      const docRef = await addDoc(winsCollectionRef, {
        title,
        content,
        date,
        postUrl,
        id: ""
      });

      await updateDoc(doc(db, 'wins', docRef.id), {
        id: docRef.id
      });

      if (image) {
        const imageRef = ref(storage, `wins-uploaded-images/${docRef.id}`);
        const uploadTask = uploadBytesResumable(imageRef, image);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(Math.round(progress));
          },
          (error) => {
            console.error('Upload failed:', error);
            setErrorMessage(`Upload failed: ${error.message}`); // Set error message
            setShowMessageModal(true); // Show error message modal
            setShowProgressModal(false);
          },
          async () => {
            const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
            await updateDoc(doc(db, 'wins', docRef.id), {
              image: imageURL,
            });
            setUploadSuccess(true);
            retrieveData();
          }
        );
      } else {
        const defaultImageRef = ref(storage, `misc/logo.jpg`);
        const defaultImageURL = await getDownloadURL(defaultImageRef);
        await updateDoc(doc(db, 'wins', docRef.id), {
          image: defaultImageURL,
        });
        setUploadSuccess(true);
        retrieveData();
      }

      console.log('Document successfully written with image URL!');
    } catch (error) {
      console.error('Error writing document:', error);
      setShowProgressModal(false);
    }
  };

  return (
    <>
      <div className="headertitle">
        <h1>Wins Management</h1>
      </div>

      <hr className="my-4 main-hr" />
      <div className='scrollable-table'>

      <div className="container-fluid mt-5">
        <div className="mb-4">
          <input
            type="text"
            className="form-control"
            placeholder="Search by title..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="table-responsive">
          <table className="table table-bordered table-hover accomplish-table-custom text-center">
            <thead className="thead-dark">
              <tr>
                <th>No.</th>
                <th>Title</th>
                <th>Content</th>
                <th>Image</th>
                <th>Date</th>
                <th>POST URL</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td className="news-data-style">{item.title}</td>
                  <td className="news-data-style">{item.content}</td>
                  <td>
                    <img src={item.image} alt="content" className="img-fluid" style={{ width: '100px', height: 'auto' }} />
                  </td>
                  <td className="news-data-style">{item.date}</td>
                  <td className="news-data-style">{item.postUrl}</td>
                  <td>
                    <button className="m-2 btn btn-warning btn-sm me-2 mb-1" onClick={() => handleUpdateShow(item)}>Update</button>
                    <button className="m-2 btn btn-danger btn-sm mb-1" onClick={() => handleDelete(item.id, item.title)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </div>
      <div className="mt-4">
        <button className="btn btn-primary" onClick={handleShow}>Add New Win</button>
        <AddWinsModal show={showModal} handleClose={handleClose} handleSave={handleAddNew} />
        <UpdateWinsModal show={showUpdateModal} handleClose={handleUpdateClose} handleSave={handleUpdate} item={currentItem} />
      </div>
      <UploadProgressModal
        show={showProgressModal}
        progress={uploadProgress}
        success={uploadSuccess}
        handleClose={handleProgressClose}
      />
      <SuccessMessageModal
        show={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        title="Success"
        message={successMessage}
      />
      <ConfirmModal
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        title="Confirm Delete"
        message={confirmMessage}
        handleConfirm={confirmAction}
      />
      <MessageModal
        show={showMessageModal}
        handleClose={() => setShowMessageModal(false)}
        title="Error"
        message={errorMessage}
      />
    </>
  );
}
