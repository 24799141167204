import Footer from "../components/Footer";
import Header from "../components/Header";
import Typed from 'typed.js';
import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import 'swiper/swiper-bundle.min.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

export default function About({ userData, clearUserData }){
    useEffect(() => {
        AOS.init({ once: true });
    }, []);

    useEffect(() => {
        AOS.refresh();
    });

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
      const [isSending, setIsSending] = useState(false);
      const [isSent, setIsSent] = useState(false);
      const [error, setError] = useState('');
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        setIsSending(true);
    
        emailjs.sendForm('service_ip8a8yb', 'template_kgc1jg6', e.target, 'e5YcQt-u63ACZRcBI')
          .then(() => {
            setIsSending(false);
            setIsSent(true);
            setError('');
            setFormData({
              name: '',
              email: '',
              subject: '',
              message: '',
            });
          }, (err) => {
            setIsSending(false);
            setError('Failed to send message. Please try again later.');
          });
      };
    
    const selectTyped = document.querySelector('.typed');
    if (selectTyped) {
        let typed_strings = selectTyped.getAttribute('data-typed-items');
        typed_strings = typed_strings.split(',');
        new Typed('.typed', {
            strings: typed_strings,
            loop: true,
            typeSpeed: 100,
            backSpeed: 50,
            backDelay: 2000
        });
    }
    const [activeTab, setActiveTab] = useState('about-tab1');

    const openTab = (tabName) => {
        setActiveTab(tabName);
    };

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFaq = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqItems = [
        {
        num: '1.',
        question: 'What is The Fortress and what services do you offer?',
        answer: 'The Fortress is a communications training firm founded by Arvin Villagarcia, formerly known as The Champ. We specialize in empowering aspiring leaders, public speakers, and beauty queens to enhance their communication skills and presence.',
        },
        {
        num: '2.',
        question: 'How does The Fortress help individuals improve their public speaking skills?',
        answer: 'We offer a holistic communications training experience where participants strengthen their foundational linguistic skills before diving into advanced communication techniques. Our programs are designed to build confidence, clarity, and charisma on stage.',
        },
        {
        num: '3.',
        question: 'Are your services available online or in-person?',
        answer: 'Currently, The Fortress operates exclusively online. We do not have a physical office or headquarters for in-person visits at this time.',
        },
        {
        num: '4.',
        question: 'What makes The Fortress different from other public speaking coaching services?',
        answer: 'Unlike traditional Q&A coaching, we emphasize a comprehensive approach to communication training. Our programs focus on refining linguistic foundations and mastering the essentials of effective communication, ensuring our clients excel in various speaking engagements.',
        },
        {
        num: '5.',
        question: `Who can benefit from The Fortress's training programs?`,
        answer: 'Our programs cater to aspiring leaders, professionals seeking to enhance their presentation skills, individuals preparing for public speaking engagements, and contestants in beauty pageants looking to refine their stage presence and communication abilities.',
        },
        {
        num: '5.',
        question: `Who can benefit from The Fortress's training programs?`,
        answer: 'Our programs cater to aspiring leaders, professionals seeking to enhance their presentation skills, individuals preparing for public speaking engagements, and contestants in beauty pageants looking to refine their stage presence and communication abilities.',
        },
        {
            num: '6.',
            question: `Who can benefit from The Fortress's training programs?`,
            answer: 'Our programs cater to aspiring leaders, professionals seeking to enhance their presentation skills, individuals preparing for public speaking engagements, and contestants in beauty pageants looking to refine their stage presence and communication abilities.',
        },
        {
            num: '7.',
            question: `How can I enroll in The Fortress's training programs?`,
            answer: 'Enrollment is straightforward. Simply visit our website to explore our program offerings and follow the registration instructions provided.',
        },
        {
            num: '8.',
            question: `What are the qualifications of The Fortress's trainers?`,
            answer: 'Our trainers are seasoned professionals with extensive experience in public speaking, communication coaching, and linguistics. They are dedicated to empowering individuals with the skills needed to succeed in various speaking engagements.',
        },
        {
            num: '9.',
            question: `Who can benefit from The Fortress's training programs?`,
            answer: 'Our programs cater to aspiring leaders, professionals seeking to enhance their presentation skills, individuals preparing for public speaking engagements, and contestants in beauty pageants looking to refine their stage presence and communication abilities.',
        },
        {
            num: '9.',
            question: `Do you offer personalized coaching sessions?`,
            answer: `Yes, we provide personalized coaching sessions tailored to meet the specific needs and goals of our clients. Whether you're a beginner or seasoned speaker, our trainers will work closely with you to maximize your potential.`,
        },
        {
            num: '10.',
            question: `What outcomes can I expect from participating in The Fortress's programs?`,
            answer: 'Participants can expect to gain enhanced confidence in public speaking, refined presentation skills, improved articulation and voice projection, and the ability to engage and influence audiences effectively.',
        },
        {
            num: '11.',
            question: `How can I get in touch with The Fortress for more information?`,
            answer: `For further inquiries or to learn more about our programs, feel free to contact us via email or through our website's contact form. We're here to assist you on your journey to becoming a more impactful communicator.`,
        },

    ];
    return(
        <>
            <Header userData={userData} clearUserData={clearUserData} />

            <div>
                <section id="abouthero" class="abouthero section dark-background">
                    <div class="container d-flex flex-column align-items-center justify-content-center text-center" data-aos="fade-up" data-aos-delay="100">
                        <h2>Welcome to the <span>Fortress</span></h2>
                        <p><span class="typed" data-typed-items="Rise, Speak,Reign"></span></p>
                    </div>
                </section>
            </div>


            <section id="portfolio" class="portfolio section">

                <div class="container section-title" data-aos="fade-up">
                    <h2>Our Partners</h2>
                </div>

                <div class="container">

                    <div class="isotope-layout" data-default-filter="*" data-layout="masonry" data-sort="original-order">


                        <div class="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay="200">

                        <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-books">
                                <img src="img/MILTSTYLEPH.jpg" class="img-fluid" alt=""/>
                                    <div class="portfolio-info">
                                        <h4>MILTSTYLEPH</h4>
                                        <a href="https://www.facebook.com/milts.tylist" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a>
                                    </div>
                            </div>

                            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
                                <img src="img/CNMODELS.jpg" class="img-fluid" alt=""/>
                                    <div class="portfolio-info">
                                        <h4>CN MODELS</h4>
                                        <a href="https://www.facebook.com/CNModels2020" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a>
                                    </div>
                            </div>

                            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-branding">
                                <img src="img/ALM.jpg" class="img-fluid" alt=""/>
                                    <div class="portfolio-info">
                                        <h4>ALM</h4>
                                        <a href="https://www.facebook.com/almeventsandtalents" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a>
                                    </div>
                            </div>
                            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-branding">
                                <img src="img/newvmedia.jpg" class="img-fluid" alt=""/>
                                    <div class="portfolio-info">
                                        <h4>New V Media</h4>
                                        <a href="https://www.facebook.com/share/KBkxwJWUcmUDyDVo/?mibextid=LQQJ4d" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a>
                                    </div>
                            </div>

                           
                            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                                <img src="img/LABERTOO.png" class="img-fluid" alt=""/>
                                    <div class="portfolio-info">
                                        <h4>LABERTOO</h4>
                                        <a href="https://www.facebook.com/labertoophotography" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a>
                                    </div>
                            </div>

                            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                                <img src="img/ABSOLUTEVALUEBYA.png" class="img-fluid" alt=""/>
                                    <div class="portfolio-info">
                                        <h4>ABSOLUTEVALUEBYA</h4>
                                        <a href="https://www.instagram.com/absolutevaluebya/" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a>
                                    </div>
                            </div>

                            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
                                <img src="img/LAKBAY CAMARINES NORTE.jpg" class="img-fluid" alt=""/>
                                    <div class="portfolio-info">
                                        <h4>LAKBAY CAMARINES NORTE</h4>
                                        <a href="https://www.facebook.com/lakbayearth2026" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a>
                                    </div>
                            </div>

                            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
                                <img src="img/449807231_882012673964450_8933053209526311616_n (1).jpg" class="img-fluid" alt=""/>
                                    <div class="portfolio-info">
                                        <h4>Tiaramorphosis</h4>
                                        <a href="https://www.facebook.com/share/AVERWDRMqc4HaKze/?mibextid=kFxxJD" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a>
                                    </div>
                            </div>

                        </div>

                    </div>

                </div>

            </section>

            

            

            


            <div>


                <section id="resume" class="resume section">


                    <div class="container section-title" data-aos="fade-up">
                        <h2>Company History/Background</h2>
                    </div>

                    <div class="container">

                        <div class="row">

                            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">

                                <h3 class="resume-title">2020-2022</h3>
                                <div class="resume-item">
                                    <p>The Fortress began in May 2020 as a startup communications training firm founded by A.B. Villagarcia, amidst the challenges of the pandemic. Even without a formal name initially, Coach A.B. Villagarcia was already mentoring talented individuals in local and national pageants through MiltstylePH. Among them were Maria Nina Soriano (Miss Universe Philippines 2020 for Pangasinan), Yna Jalin (Miss Philippines Earth 2021 for Sual, Pangasinan), Emarvielyn Fernandez (Miss Philippines Earth 2021 for Urdaneta, Pangasinan), and Ivylou Borbon (Miss Universe Philippines 2022 for Pangasinan). </p>
                                </div>

                                <div class="resume-item">
                                    <h4>2022-2023</h4>
                                    <p>Their trust and support inspired the birth of The Champ in 2022, although it lacked a dedicated platform until The Miss Hundred Islands 2023.

                                        During the peak of the Miss Hundred Islands season, The Champ achieved remarkable success with members such as Monica Valle (The Miss Hundred Islands 2023), Francine Atasha Mesa (Tourism), Aizha Veronica Loyal (Environmental Advocate), and Paula Montaniel (First Runner-Up) bringing home accolades. These victories opened doors nationally and internationally, including the mentorship of Maria Socorro Luz Aspe, crowned Miss Teen Universe Asia 2023 in Barranquilla, Colombia, alongside other outstanding beauty queens like Rona Lalaine Tamayo Lopez (Top 10 Finalist at Miss Grand International Philippines) and Vanessa F. Baraquio (Second Runner-Up at Hiyas ng Pilipinas 2023).
                                    </p>
                                </div>

                            </div>

                            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                                <h3 class="resume-title">Present Day</h3>
                                <div class="resume-item">
                                    <h4>2024</h4>
                                    <p><em>Today, The Fortress stands as a beacon of empowerment, endorsed by a host of reigning beauty queens. Their support has transformed the firm into The Fortress, dedicated to empowering aspiring public speakers and beauty queens alike, helping them rise, speak and reign in their chosen fields. </em></p>
                                </div>


                            </div>

                        </div>

                    </div>

                </section>
            </div>

            <div>

                <section id="stats" class="stats section accent-background">

                    <img src="../../img/stats-cover-design.png" alt="" data-aos="fade-in" />

                    <div class="container position-relative" data-aos="fade-up" data-aos-delay="100">

                        <div class="row gy-4">

                            <div class="col-lg-3 col-md-6">
                                <div class="stats-item text-center w-100 h-100">
                                    <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="0" class="purecounter">40+</span>
                                    <p>Clients</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="stats-item text-center w-100 h-100">
                                    <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="0" class="purecounter">20+</span>
                                    <p>Wins</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="stats-item text-center w-100 h-100">
                                    <span data-purecounter-start="0" data-purecounter-end="1453" data-purecounter-duration="0" class="purecounter">7</span>
                                    <p>Collaborations</p>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>
            </div>
            <div>
                <section id="featured-services" class="featured-services section">
                    <div class="container">
                        <div class="row gy-4">
                            <div class="col-lg-4 col-md-6 col-sm-12 d-flex" data-aos="fade-up" data-aos-delay="100">
                                <div class="service-item position-relative">
                                    <div class="icon"><i class="bi bi-list-task"></i></div>
                                    <h4><a href="" class="stretched-link">VISION:</a></h4>
                                    <p>To become a global beacon of empowerment and excellence for aspiring public speakers and beauty queens, known for our transformative approach. We envision individuals radiating humility, confidence, and eloquence, empowered to lead with integrity and inspire positive change.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 col-sm-12 d-flex" data-aos="fade-up" data-aos-delay="200">
                                <div class="service-item position-relative">
                                    <div class="icon"><i class="bi bi-bounding-box-circles icon"></i></div>
                                    <h4><a href="" class="stretched-link">MISSION:</a></h4>
                                    <p>To empower and elevate aspiring public speakers and beauty queens globally fostering humility and excellence cultivating confidence, articulating leadership, and inspiring individuals to achieve their highest potential in their chosen fields.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 col-sm-12 d-flex" data-aos="fade-up" data-aos-delay="300">
                                <div class="service-item position-relative">
                                    <div class="icon"><i class="bi bi-calendar4-week icon"></i></div>
                                    <h4><a href="" class="stretched-link">GOALS:</a></h4>
                                    <p>Guided by our core values, The Fortress is committed to empowering aspiring public speakers and beauty queens globally, aiming to achieve recognition at local, national, and international levels while producing impactful title holders and achievers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            
            <div>
                <section id="about" class="about section">

                    <div class="container" data-aos="fade-up">

                        <div class="row g-4 g-lg-5" data-aos="fade-up" data-aos-delay="200">

                            <div class="col-lg-5">
                                <div class="about-img">
                                    <img src="../../img/the-fortress-logo-with-tagline.png" class="img-fluid" alt="" />
                                </div>
                            </div>

                            <div class="col-lg-7">
                                <h3 class="pt-0 pt-lg-5">Empowering Individuals to Rise, Speak, and Reign in Public Speaking and Beauty Pageants</h3>

                                <div className="tab">
                                    <button
                                        className={`tablinks ${activeTab === 'about-tab1' ? 'active' : ''}`}
                                        onClick={() => openTab('about-tab1')}
                                    >
                                        Rise
                                    </button>
                                    <button
                                        className={`tablinks ${activeTab === 'about-tab2' ? 'active' : ''}`}
                                        onClick={() => openTab('about-tab2')}
                                    >
                                        Speak
                                    </button>
                                    <button
                                        className={`tablinks ${activeTab === 'about-tab3' ? 'active' : ''}`}
                                        onClick={() => openTab('about-tab3')}
                                    >
                                        Reign
                                    </button>
                                </div>
                               
                                <div className="tab-content">
                                    <div
                                        id="about-tab1"
                                        className={`tabcontent ${activeTab === 'about-tab1' ? 'active' : ''}`}
                                    >
                                        <div className="d-flex align-items-center mt-4">
                                            <i className="bi bi-check2"></i>
                                            <h4>Empowerment and Growth</h4>
                                        </div>
                                        <p>a) Increase participant enrollment in training programs by 30% within the next year, leveraging targeted marketing strategies to attract aspiring public speakers and beauty pageant contestants locally, nationally, and internationally.</p>
                                    </div>
                                    <div
                                        id="about-tab2"
                                        className={`tabcontent ${activeTab === 'about-tab2' ? 'active' : ''}`}
                                    >
                                        <div className="d-flex align-items-center mt-4">
                                            <i className="bi bi-check2"></i>
                                            <h4>Communication and Articulation</h4>
                                        </div>
                                        <p>a) Develop and launch a user-friendly online coaching platform by Q3 2024, ensuring scalability and international accessibility to effectively train mentees in communication and articulation skills.</p>
                                    </div>
                                    <div
                                        id="about-tab3"
                                        className={`tabcontent ${activeTab === 'about-tab3' ? 'active' : ''}`}
                                    >
                                        <div className="d-flex align-items-center mt-4">
                                            <i className="bi bi-check2"></i>
                                            <h4>Leadership and Excellence</h4>
                                        </div>
                                        <p>a) Implement a structured feedback system and continuous improvement process to achieve a participant satisfaction rate of 85% by Q4 2024, fostering leadership qualities among mentees.</p>
                                        <p>b) Celebrate the achievements of mentees by producing at least five notable title holders or achievers annually, positioning The Fortress as a leader in fostering leadership and excellence in public speaking and beauty pageants.</p>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </section>
            </div>

            <div>

                <section id="services" class="services section">

                    <div class="container section-title" data-aos="fade-up">
                        <h2>Core Values:</h2>
                        <p style={{fontSize: 18}}>Grounded in our core values of Growth, Openness, and Dedication, we strive to embody strength, guidance, and integrity in everything we do:</p>
                    </div>

                    <div class="container">

                        <div class="row gy-4">

                            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div class="service-item  position-relative">
                                    <h3>Growth:</h3>
                                    <a></a>
                                    <p>Commitment to continuous improvement and personal development.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                                <div class="service-item position-relative">
                                    <h3>Openness: </h3>
                                    <a></a>
                                    <p>Embrace transparency, honesty, and open communication.</p>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                                <div class="service-item position-relative">
                                    <h3>Dedication: </h3>
                                    <a></a>
                                    <p>Passionate pursuit of excellence and dedication to our mission.</p>
                                </div>
                            </div>


                        </div>

                    </div>

                </section>
            </div>
            <div>


                <section id="faq" className="faq section">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="content px-xl-5">
                                    <h3><span>Frequently Asked </span><strong>Questions</strong></h3>
                                </div>
                            </div>

                            <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
                                <div className="faq-container">
                                    {faqItems.map((item, index) => (
                                        <div key={index} className={`faq-item ${activeIndex === index ? 'faq-active' : ''}`}>
                                            <h3 onClick={() => toggleFaq(index)}>
                                                <span className="num">{item.num}</span> <span>{item.question}</span>
                                            </h3>
                                            <div className="faq-content">
                                                <p>{item.answer}</p>
                                            </div>
                                            <i className={`faq-toggle bi ${activeIndex === index ? 'bi-chevron-up' : 'bi-chevron-down'}`} onClick={() => toggleFaq(index)}></i>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <div>
                <section id="contact" className="contact section">
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Contact</h2>
                        <p style={{fontSize: 18}}>Contact Us: We’d Love to Hear from You! Please fill out the form below and we'll get back to you shortly.</p>
                    </div>
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <form onSubmit={handleSubmit} className="php-email-form" data-aos="fade-up" data-aos-delay="300">
                            <div className="row gy-4">
                                <div className="col-md-6">
                                    <input
                                        style={{padding: 24}}

                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Your Name"
                                        required
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <input
                                        style={{padding: 24}}

                                        type="email"
                                        className="form-control"
                                        name="email"
                                        placeholder="Your Email"
                                        required
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        style={{padding: 24}}

                                        type="text"
                                        className="form-control"
                                        name="subject"
                                        placeholder="Subject"
                                        required
                                        value={formData.subject}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <textarea
                                        style={{padding: 24}}

                                        className="form-control"
                                        name="message"
                                        rows="6"
                                        placeholder="Message"
                                        required
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-12 text-center">
                                    {isSending && <div className="loading">Loading</div>}
                                    {error && <div className="error-message">{error}</div>}
                                    {isSent && <div className="sent-message">Your message has been sent. Thank you!</div>}
                                    <button type="submit" disabled={isSending}>Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
            {/* <div>
                <section id="partners" className="partners section">
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Our Partners</h2>
                        <p style={{ fontSize: 18 }}>We are proud to collaborate with the following organizations:</p>
                    </div>
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div className="row gy-4 align-items-center justify-content-center">
                            <div className="col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center">
                                <img src="img/ABSOLUTEVALUEBYA.png" className="img-fluid" alt="Partner 1" />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center">
                                <img src="img/ALM.jpg" className="img-fluid" alt="Partner 2" />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center">
                                <img src="img/CNMODELS.jpg" className="img-fluid" alt="Partner 3" />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center">
                                <img src="img/LABERTOO.png" className="img-fluid" alt="Partner 4" />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center">
                                <img src="img/LAKBAY CAMARINES NORTE.jpg" className="img-fluid" alt="Partner 5" />
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center">
                            <img src="img/TEAM.png" className="img-fluid" alt="Partner 7" />

                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center">
                                <img src="img/MILTSTYLEPH.jpg" className="img-fluid" alt="Partner 6" />
                            </div>
                            
                        </div>
                    </div>
                </section>
            </div> */}

            <Footer />
        </>
    )
}