import React, { useEffect } from 'react';

const UpdatePaymentStatusModal = ({ show, handleClose, handleSave, item }) => {
    useEffect(() => {
        if (show) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [show]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedItem = {
            ...item,
            status: 'confirmed'
        };
        handleSave(updatedItem);
        handleClose();
    };

    return (
        <>
            {show && <div className="modal-backdrop fade show"></div>}
            <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="updatePaymentStatusModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="global-modal-title modal-title" id="updatePaymentStatusModalLabel">Confirm Payment Status</h5>
                            <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="global-modal-control" id="updateForm" name="update" role="form" onSubmit={handleSubmit}>
                                <div className="global-modal-label modal-body">
                                    <p>Are you sure you want to confirm this payment?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Confirm
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdatePaymentStatusModal;
