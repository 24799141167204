import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MultiStepProgressBar from '../../components/MultiStepProgressBar'; // Adjust the path as necessary
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config";

export default function TraineeSession() {
  const location = useLocation();
  const { item } = location.state || {};
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [packageData, setPackageData] = useState(null);

  const [qa, setQa] = useState(null);
  const [asses, setAssess] = useState(null);

  const stepsQA = ['Session 1', 'Session 2', 'Session 3', 'Session 4', 'Session 5', 'Session 6', 'Session 7'];
  const stepsAssessments = ['Session 1', 'Session 2'];

  const [userPackageReading, setUserPackageReading] = useState([0, 0]);

  useEffect(() => {
    const getPackagesSessionDetails = async () => {
      try {
        if (userData) {
          const packageDocRef = doc(db, 'registered_trainees', item.id);
          const packageDocSnap = await getDoc(packageDocRef);
          if (packageDocSnap.exists()) {
            const packageData = packageDocSnap.data();
            console.log("packageData");

            console.log(packageData);
            setUserPackageReading([packageData["assessment_took"] || 0, packageData["session_took"] || 0]);
          }
        }
      } catch (error) {
        console.error('Error fetching user package session details:', error);
      }
    };

    getPackagesSessionDetails();
  }, [userData]);


  useEffect(() => {
    const fetchUserData = async () => {
      if (item?.id) {
        console.log('Fetching user data for ID:', item.id);
        const userDoc = await getDoc(doc(db, 'users', item.id));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log('User data:', userData);
          setUserData({ ...userData, userId: item.id });
        } else {
          console.error('No such user document!');
        }
      }
    };

    fetchUserData();
  }, [item]);

  useEffect(() => {
    const fetchPackageData = async () => {
      if (userData?.currentPackage) {
        const packageKey = userData.currentPackage.toLowerCase().replace('-', '_');
        console.log('Fetching package data for package:', packageKey);

        const packageDocRef = doc(db, 'packages', 'K9VgkoMsGmu4Zu3wztv9');
        const packageDocSnap = await getDoc(packageDocRef);
        if (packageDocSnap.exists()) {
          const packageData = packageDocSnap.data()[packageKey];

          console.log('Package data:', packageData);
          setPackageData(packageData);
          if (packageData) {
            setQa(Array.from({ length: parseInt(packageData[3]) }, (_, i) => `Session ${i + 1}`));
            setAssess(Array.from({ length: parseInt(packageData[4]) }, (_, i) => `Session ${i + 1}`));
          }
        } else {
          console.error(`No such package document! Package Key: ${packageKey}`);
        }
      }
    };

    if (userData) {
      fetchPackageData();
    }
  }, [userData]);

  useEffect(() => {
    if (userData && packageData) {
      setIsLoading(false);
    }
  }, [userData, packageData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  console.log('userData:', userData); // Log userData to check structure and ensure userId is available

  return (
    <>
      <div className="headertitle">
        <h1>{`Trainee Session - ${userData?.name || 'No Name Available'}`}</h1>
      </div>

      <hr className="my-2 main-hr" />

      <div className="ts-container container mt-4">
        <div className="trainee-session-container">
          {userData?.image && (
            <div className='trainee-session-container-img'>
              <img src={userData.image} alt="Trainee" />
            </div>
          )}
          <div>
            <h4>{userData?.name ? `${userData.name}` : 'Trainee Session'}</h4>
            {userData?.email && <p>{userData.email}</p>}
          </div>
        </div>
        
        <div className='container mt-4 trainee-details-container'>
          <div className="details-row">
            <div className="details-pair">
              <p><strong>Age:</strong> {userData?.age || 'N/A'}</p>
              <p><strong>Birthday:</strong> {userData?.birthdate || 'N/A'}</p>
            </div>
            <div className="details-pair">
              <p><strong>Address:</strong> {userData?.address || 'N/A'}</p>
              <p><strong>Contact:</strong> {userData?.phone || 'N/A'}</p>
            </div>
            <div className="details-pair">
              <p><strong>Name of Handler:</strong> {userData?.handler || 'N/A'}</p>
              <p><strong>Preferred First Schedule</strong> {userData?.first_schedule || 'N/A'}</p>

            </div>
          </div>


        </div>

        <div className="big-card trainee-session-card card mt-5">
          <div className="big-card trainee-session-card-body card-body">
            <div className='big-card'>
              <h2 className='trainee-session-headers session-package-title'>{packageData[0]}</h2>
              <h3 className='trainee-session-headers'>Q & A / COMMUNICATION COACHING <span style={{ color: ' #3AB7FA' }}>SESSIONS</span></h3>
              <MultiStepProgressBar steps={qa} initialStep={parseInt(userPackageReading[1] + 1) || 0} userId={userData?.userId} type="session" />
            </div>
            <div className='big-card'>
              <h3 className='trainee-session-headers'>ASSESSMENTS <span style={{ color: ' #3AB7FA' }}>SESSIONS</span></h3>
              <MultiStepProgressBar steps={asses} initialStep={parseInt(userPackageReading[0] + 1) || 0} userId={userData?.userId}  type="assessment" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
