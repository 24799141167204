import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../config';
import DashboardCard from '../../components/DashboardCard';

export default function Dashboard() {
  const [totalAccomplishments, setTotalAccomplishments] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalRegisteredTrainees, setTotalRegisteredTrainees] = useState(0);
  const [totalTestimonials, setTotalTestimonials] = useState(0);
  const [totalNews, setTotalNews] = useState(0);

  useEffect(() => {
    const fetchAccomplishmentsCount = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'wins'));
        setTotalAccomplishments(querySnapshot.size);
      } catch (error) {
        console.error('Error fetching accomplishments count: ', error);
      }
    };

    fetchAccomplishmentsCount();
  }, []);

  useEffect(() => {
    const fetchPendingRegistrations = async () => {
      try {
        const pendingQuery = query(collection(db, 'for_confirmation_payment'), where('status', '==', 'pending'));
        const querySnapshot = await getDocs(pendingQuery);
        setTotalPending(querySnapshot.size);
      } catch (error) {
        console.error('Error fetching pending registrations count: ', error);
      }
    };

    fetchPendingRegistrations();
  }, []);

  useEffect(() => {
    const fetchRegisteredTraineesCount = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'registered_trainees'));
        setTotalRegisteredTrainees(querySnapshot.size);
      } catch (error) {
        console.error('Error fetching registered trainees count: ', error);
      }
    };

    fetchRegisteredTraineesCount();
  }, []);

  useEffect(() => {
    const fetchTestimonialsCount = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'members'));
        setTotalTestimonials(querySnapshot.size);
      } catch (error) {
        console.error('Error fetching testimonials count: ', error);
      }
    };

    fetchTestimonialsCount();
  }, []);

  useEffect(() => {
    const fetchNewsCount = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'news'));
        setTotalNews(querySnapshot.size);
      } catch (error) {
        console.error('Error fetching news count: ', error);
      }
    };

    fetchNewsCount();
  }, []);

  const cardStyle = {
    backgroundColor: '#3AB7FA'
  };

  return (
    <>
      <div className="headertitle">
        <h1>Dashboard</h1>
      </div>

      <hr className="my-4 main-hr" />
      
      <div>
        <div className="container-fluid mt-5 dashboard-container">
          <h6>Registration Data</h6>
          <div className="row">
            <DashboardCard label="Total Registered Trainees" counter={totalRegisteredTrainees} style={cardStyle} />
            <DashboardCard label="Pending Registrations" counter={totalPending} style={cardStyle} />
          </div>
          
          <h6>Other Data</h6>
          <div className="row">
            <DashboardCard label="Total Wins" counter={totalAccomplishments} style={cardStyle} />
            <DashboardCard label="Total Members Testimonials" counter={totalTestimonials} style={cardStyle} />
            <DashboardCard label="Total News" counter={totalNews} style={cardStyle} />
          </div>
        </div>
      </div>
    </>
  );
}
