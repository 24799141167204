import React, { useState, useEffect } from 'react';
import AddMemberModal from '../../components/MembersComponents/AddMemberModal';
import UpdateMemberModal from '../../components/MembersComponents/UpdateMemberModal';
import UploadProgressModal from '../../components/UploadProgressModal';
import SuccessMessageModal from '../../components/SuccessMessageModal';
import ConfirmModal from '../../components/ConfirmModal';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject, uploadBytes } from 'firebase/storage';
import { db, storage } from '../../config';

export default function Members() {
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentItem, setCurrentItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmAction, setConfirmAction] = useState(() => () => {});

  useEffect(() => {
    retrieveData();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const filtered = data.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [searchQuery, data]);

  const retrieveData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'members'));
      const membersData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setData(membersData);
      setFilteredData(membersData);
    } catch (error) {
      console.error('Error retrieving documents: ', error);
    }
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleProgressClose = () => {
    setShowProgressModal(false);
    setUploadProgress(0);
    setUploadSuccess(false);
  };

  const handleUpdateShow = (item) => {
    setCurrentItem(item);
    setShowUpdateModal(true);
  };

  const handleUpdateClose = () => {
    setCurrentItem(null);
    setShowUpdateModal(false);
  };

  const handleUpdate = async (updatedItem) => {
    console.log(`Update item with id: ${updatedItem.id}`);
    setShowProgressModal(true); 
    try {
      const docRef = doc(db, 'members', updatedItem.id);
  
      const oldDoc = await getDoc(docRef);
      const oldName = oldDoc.data().name;
  
      await updateDoc(docRef, {
        name: updatedItem.name,
        titles: updatedItem.titles,
        testimonials: updatedItem.testimonials || '',
        date: new Date().toISOString().split('T')[0],
      });
  
      if (updatedItem.image) {
        const imageRef = ref(storage, `members-uploaded-images/${updatedItem.id}`);
        const uploadTask = uploadBytesResumable(imageRef, updatedItem.image);
  
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(Math.round(progress));
          },
          (error) => {
            console.error('Upload failed:', error);
            setShowProgressModal(false);
          },
          async () => {
            const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
            await updateDoc(docRef, {
              image: imageURL,
            });
  
            
  
            setUploadSuccess(true);
            retrieveData();
          }
        );
      } else {
        const oldImageRef = ref(storage, `members-uploaded-images/${updatedItem.id}`);
        const newImageRef = ref(storage, `members-uploaded-images/${updatedItem.id}`);
        const imageURL = await getDownloadURL(oldImageRef);
  
        const response = await fetch(imageURL);
        const blob = await response.blob();
        await uploadBytes(newImageRef, blob);
  
        const newImageURL = await getDownloadURL(newImageRef);
        await updateDoc(docRef, {
          image: newImageURL,
        });
  
        try {
          await deleteObject(oldImageRef);
        } catch (error) {
          console.warn('Old image not found or already deleted:', error);
        }
  
        setUploadSuccess(true);
        retrieveData();
      }
  
      console.log('Document successfully updated!');
    } catch (error) {
      console.error('Error updating document:', error);
      setShowProgressModal(false);
    } finally {
      handleUpdateClose();
    }
  };
  
  const handleDelete = (id) => {
    setConfirmMessage('Are you sure you want to delete this member?');
    setConfirmAction(() => () => confirmDelete(id));
    setShowConfirmModal(true);
  };

  const confirmDelete = async (id) => {
    setShowConfirmModal(false);
    console.log(`Delete item with id: ${id}`);
    try {
      const docRef = doc(db, 'members', id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const docData = docSnapshot.data();
        const imageUrl = docData.image;

        if (!imageUrl.includes('https://firebasestorage.googleapis.com/v0/b/thefortresswebsite-d5c43.appspot.com/o/misc%2Flogo.jpg?alt=media&token=5186a951-e149-469e-a2c3-49aa091bb5f1')) {
          const imageRef = ref(storage, `members-uploaded-images/${id}`);
          await deleteObject(imageRef);
        }

        await deleteDoc(docRef);

        setSuccessMessage('Deleted Successfully!');
        setShowSuccessModal(true);
        retrieveData();
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };

  const handleAddNew = async (formData) => {
    console.log('Form Data:', formData);
    setShowProgressModal(true);
    try {
      const { name, titles, testimonials, image } = formData;
      const date = new Date().toISOString().split('T')[0];

      const membersCollectionRef = collection(db, 'members');

      const docRef = await addDoc(membersCollectionRef, {
        name,
        titles,
        testimonials: testimonials || '',
        date,
      });

      if (image) {
        const imageRef = ref(storage, `members-uploaded-images/${docRef.id}`);
        const uploadTask = uploadBytesResumable(imageRef, image);
    
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(Math.round(progress));
          },
          (error) => {
            console.error('Upload failed:', error);
            setShowProgressModal(false);
          },
          async () => {
            const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
            await updateDoc(doc(db, 'members', docRef.id), {
              image: imageURL,
            });
            setUploadSuccess(true);
            retrieveData();
          }
        );
      } else {
        const defaultImageRef = ref(storage, `misc/logo.jpg`);
        const defaultImageURL = await getDownloadURL(defaultImageRef);
        await updateDoc(doc(db, 'members', docRef.id), {
          image: defaultImageURL,
        });
        setUploadSuccess(true);
        retrieveData();
      }

      console.log('Document successfully written with image URL!');
    } catch (error) {
      console.error('Error writing document: ', error);
      setShowProgressModal(false);
    }
  };

  return (
    <>
      <div className="headertitle">
        <h1>Members Management</h1>
      </div>

      <hr className="my-4 main-hr" />
        <div className='testminonial-table '> 
        <div className="container-fluid mt-5  ">
          <div className="mb-4">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className=" table-responsive ">
            <table className="table table-bordered table-hover accomplish-table-custom text-center">
              <thead className="thead-dark">
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Titles</th>
                  <th>Testimonials</th>
                  <th>Image</th>
                  <th>Date Added</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td className="members-data-style">{item.name}</td>
                    <td className="members-data-style">{item.titles}</td>
                    <td className="members-data-style">{item.testimonials || 'N/A'}</td>
                    <td>
                      <img src={item.image} alt="content" className="img-fluid" style={{ width: '100px', height: 'auto' }} />
                    </td>
                    <td className="members-data-style">{item.date}</td>
                    <td>
                      <button className="m-2 btn btn-warning btn-sm me-2 mb-1" onClick={() => handleUpdateShow(item)}>Update</button>
                      <button className="m-2 btn btn-danger btn-sm mb-1" onClick={() => handleDelete(item.id)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-4">
          <button className="btn btn-primary" onClick={handleShow}>Add New Member</button>
          <AddMemberModal show={showModal} handleClose={handleClose} handleSave={handleAddNew} />
          <UpdateMemberModal show={showUpdateModal} handleClose={handleUpdateClose} handleSave={handleUpdate} item={currentItem} />
        </div>
      </div>
      <UploadProgressModal
        show={showProgressModal}
        progress={uploadProgress}
        success={uploadSuccess}
        handleClose={handleProgressClose}
      />
      <SuccessMessageModal
        show={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        title="Success"
        message={successMessage}
      />
      <ConfirmModal
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        title="Confirm Delete"
        message={confirmMessage}
        handleConfirm={confirmAction}
      />
    </>
  );
}
