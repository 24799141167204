import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, deleteDoc, updateDoc, query, where } from "firebase/firestore";
import { db } from "../../config"; 
import ConfirmModal from '../../components/ConfirmModal'; 

export default function Trainees() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showUnenrollModal, setShowUnenrollModal] = useState(false); 
  const [selectedTrainee, setSelectedTrainee] = useState(null); 

  useEffect(() => {
    const fetchData = async () => {
      const traineesSnapshot = await getDocs(collection(db, 'registered_trainees'));
      const traineesData = [];
      traineesSnapshot.forEach(doc => {
        traineesData.push({ id: doc.id, ...doc.data() });
      });

      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersData = [];
      usersSnapshot.forEach(doc => {
        usersData.push({ id: doc.id, ...doc.data() });
      });

      const mergedData = traineesData.map(trainee => {
        const user = usersData.find(user => user.id === trainee.userId);
        return {
          ...trainee,
          name: user ? `${user.firstName} ${user.lastName}` : "",
          email: user?.email || "",
          package: user?.currentPackage || "",
          image: user?.image || "",
          date_joined: user?.first_schedule || "",
        };
      });

      setData(mergedData);
    };

    fetchData();
  }, []);

  const handleViewSession = (item) => {
    navigate(`/admin/trainees/${item.id}/sessions`, { state: { item } });
  };

  const handleShowUnenrollModal = (trainee) => {
    setSelectedTrainee(trainee);
    setShowUnenrollModal(true);
  };

  const handleConfirmUnenroll = async () => {
    if (selectedTrainee) {
      const traineeId = selectedTrainee.id;
      const userId = selectedTrainee.userId;

      try {
        // Remove from registered_trainees
        await deleteDoc(doc(db, 'registered_trainees', traineeId));

        // Update user in users collection
        const userDocRef = doc(db, 'users', userId);
        await updateDoc(userDocRef, {
          currentPackage: "NONE",
          selected_package: ""
        });

        // Remove from for_confirmation_payment
        const paymentQuery = query(collection(db, 'for_confirmation_payment'), where('userId', '==', userId));
        const paymentSnapshot = await getDocs(paymentQuery);
        paymentSnapshot.forEach(async (paymentDoc) => {
          await deleteDoc(doc(db, 'for_confirmation_payment', paymentDoc.id));
        });

        // Remove the trainee from the local state
        setData(data.filter(item => item.id !== traineeId));
      } catch (error) {
        console.error('Error unenrolling trainee:', error);
      }

      setShowUnenrollModal(false);
      setSelectedTrainee(null);
    }
  };

  const handleCloseUnenrollModal = () => {
    setShowUnenrollModal(false);
    setSelectedTrainee(null);
  };

  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="headertitle">
        <h1>Registered Trainees</h1>
      </div>

      <hr className="my-4 main-hr" />
      <div className='scrollable-table '>
      <div className="container-fluid mt-5">
        <div className="mb-4">
          <input
            type="text"
            className="form-control"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="table-responsive">
          <table className="table table-bordered table-hover accomplish-table-custom text-center">
            <thead className="thead-dark">
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Package</th>
                <th>Profile Image</th>
                <th>Date Joined</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td className="trainees-data-style">{item.name}</td>
                  <td className="trainees-data-style">{item.email}</td>
                  <td className="trainees-data-style">{item.package}</td>
                  <td>
                    {item.image ? (
                      <img
                      src={item.image}
                      alt="profile"
                      className="img-fluid"
                      
                      style={{ width: '100px', height: 'auto', display: 'block', margin: 'auto' }}
                    />
                    ): (
                      "User still have not uploaded an profile"
                    )}
                    
                  </td>
                  <td className="trainees-data-style">{item.date_joined}</td>
                  <td>
                    <button
                      className="m-2 btn btn-success btn-sm mb-1"
                      onClick={() => handleViewSession(item)}
                    >
                      View Session
                    </button>
                    <button
                      className="m-2 btn btn-danger btn-sm mb-1"
                      onClick={() => handleShowUnenrollModal(item)}
                    >
                      Unenroll
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </div>
      <ConfirmModal
        show={showUnenrollModal}
        handleClose={handleCloseUnenrollModal}
        title="Confirm Unenroll"
        message="Are you sure you want to unenroll this trainee?"
        handleConfirm={handleConfirmUnenroll}
      />
    </>
  );
}
