import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, addDoc, updateDoc, Timestamp  } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../config";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ErrorMessageModal from "../components/ErrorMessageModal";
import { format } from 'date-fns';

export default function Login({ setUserData }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSignUpMode, setIsSignUpMode] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const userDataStorage = JSON.parse(localStorage.getItem("userData"));
        if (userDataStorage) {
            setUserData(userDataStorage);
            navigateUser(userDataStorage.type);
        }
    }, []);

    useEffect(() => {
        const signInBtn = document.getElementById('sign-in-btn');
        const signUpBtn = document.getElementById('sign-up-btn');
        const signInBtn2 = document.getElementById('sign-in-btn2');
        const signUpBtn2 = document.getElementById('sign-up-btn2');
        const container = document.querySelector('.login_container');

        if (signInBtn && signUpBtn) {
            signInBtn.addEventListener('click', () => {
                container.classList.remove('sign-up-mode');
                container.classList.remove('sign-up-mode2');
                setIsSignUpMode(false);
            });
            signUpBtn.addEventListener('click', () => {
                container.classList.add('sign-up-mode');
                container.classList.remove('sign-up-mode2');
                setIsSignUpMode(true);
            });
        }

        if (signInBtn2 && signUpBtn2) {
            signInBtn2.addEventListener('click', () => {
                container.classList.remove('sign-up-mode2');
                setIsSignUpMode(false);
            });
            signUpBtn2.addEventListener('click', () => {
                container.classList.add('sign-up-mode2');
                setIsSignUpMode(true);
            });
        }

        if (window.location.hash === '#sign-up-mode') {
            container.classList.add('sign-up-mode');
            setIsSignUpMode(true);
        }
    }, []);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setErrorMessage("Please enter a valid email address.");
            setShowError(true);
            return;
        }
        if (password.length < 6) {
            setErrorMessage("Password must be at least 6 characters long.");
            setShowError(true);
            return;
        }

        try {
            const usersCollection = collection(db, "users");
            const q = query(usersCollection, where("email", "==", email), where("password", "==", password));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    const userData = doc.data();
                    const userType = userData.type;

                    if (userType === "CLIENT_USER" || userType === "ADMIN_USER") {
                        localStorage.setItem("userData", JSON.stringify(userData));
                        setUserData(userData);
                        setShowError(false);
                        navigateUser(userType);
                    } else {
                        setErrorMessage("Invalid user type");
                        setShowError(true);
                    }
                });
            } else {
                setErrorMessage("Invalid email or password!");
                setShowError(true);
            }
        } catch (error) {
            setErrorMessage(`Error logging in: ${error.message}`);
            setShowError(true);
        }
    };
    const formatDate = (timestamp) => {
        const date = timestamp.toDate();
        return format(date, 'MM/dd/yy HH:mm:ss');
      };
    const handleSignUp = async (e) => {
        e.preventDefault();
    
        if (!validateEmail(email)) {
            setErrorMessage("Please enter a valid email address.");
            setShowError(true);
            return;
        }
        if (password.length < 6) {
            setErrorMessage("Password must be at least 6 characters long.");
            setShowError(true);
            return;
        }
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match!");
            setShowError(true);
            return;
        }
    
        try {
            const usersCollection = collection(db, "users");
            const accountCreationDate = Timestamp.fromDate(new Date()); // Get current date and time
    
            const newUser = {
                email,
                password,
                firstName,
                lastName,
                registration_step: 1,
                name: `${firstName} ${lastName}`,
                currentPackage: "NONE",
                type: "CLIENT_USER",
                account_date_created: formatDate(accountCreationDate) // Add account creation date
            };
    
            const userDocRef = await addDoc(usersCollection, newUser);
            const userId = userDocRef.id;
    
            await updateDoc(userDocRef, { id: userId });
    
            const updatedUser = { ...newUser, id: userId };
            localStorage.setItem("userData", JSON.stringify(updatedUser));
            setUserData(updatedUser);
            setShowError(false);
            navigateUser(updatedUser.type);
        } catch (error) {
            setErrorMessage(`Error signing up: ${error.message}`);
            setShowError(true);
        }
    };

    const navigateUser = (userType) => {
        if (userType === "ADMIN_USER") {
            navigate("/admin");
        } else if (userType === "CLIENT_USER") {
            navigate("/client");
        }
    };

    return (
        <>
            <Header />
            <div className="login_body">
                <div className="login_container">
                    <div className="signin-signup">
                        <form onSubmit={handleLogin} className="sign-in-form">
                            <h2 className="title">Sign in</h2>
                            <div className="input-field">
                                <i className="fas fa-user"></i>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" />
                            </div>
                            <div className="input-field">
                                <i className="fas fa-lock"></i>
                                <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                            </div>
                            <input type="submit" value="Login" className="login_btn" />
                            <p className="account-text"> Don't have an account? <a href="#" id="sign-up-btn2">Sign up</a> </p>
                        </form>
                        <form onSubmit={handleSignUp} className="sign-up-form">
                            <h4 className="title">Sign up</h4>
                            <div className="input-field">
                                <i className="fas fa-user"></i>
                                <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" placeholder="First Name" />
                            </div>
                            <div className="input-field">
                                <i className="fas fa-user"></i>
                                <input value={lastName} onChange={(e) => setLastName(e.target.value)} type="text" placeholder="Last Name" />
                            </div>
                            <div className="input-field">
                                <i className="fas fa-envelope"></i>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Email" />
                            </div>
                            <div className="input-field">
                                <i className="fas fa-lock"></i>
                                <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                            </div>
                            <div className="input-field">
                                <i className="fas fa-lock"></i>
                                <input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type="password" placeholder="Confirm Password" />
                            </div>
                            <input type="submit" value="Next" className="login_btn" />
                            <p className="account-text"> Already have an account? <a href="#" id="sign-in-btn2">Sign in</a> </p>
                        </form>
                    </div>
                    <div className="panels-container">
                        <div className="panel left-panel">
                            <div className="content">
                                <h3> Member of The <font style={{ color: '#3AB7FA' }}>Fortress</font>? </h3>
                                <button className="login_btn" id="sign-in-btn">Sign in</button>
                            </div>
                        </div>
                        <div className="panel right-panel">
                            <div className="content">
                                <h3> New to The <font style={{ color: '#3AB7FA' }}>Fortress</font>? </h3>
                                <button className="login_btn" id="sign-up-btn">Sign up</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ErrorMessageModal show={showError} handleClose={() => setShowError(false)} title="Login Error" message={errorMessage} />
            <Footer />
        </>
    );
}
