import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../src/components/ConfirmModal';

export default function Header({ userData, clearUserData }) {
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isHamburgerActive, setIsHamburgerActive] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);

  const handleLogout = () => {
    clearUserData();
    navigate('/login');
  };

  const toggleHamburger = () => {
    setIsHamburgerActive(!isHamburgerActive);
    setIsMenuActive(!isMenuActive);
  };



  const handleAccountClick = (event) => {
    event.preventDefault();
    if (userData) {
      const rolePage = userData.type === 'ADMIN_USER' ? '/admin' : '/client';
      navigate(rolePage);
    }
  };

  return (
    <>
      <header className="header d-flex align-items-center sticky-top">
        <div className="nav-container">
          <div className='home-logo-item container-fluid'>
            <a href="/home" className="logo d-flex align-items-center">
              <h1 className="sitename">The <span>Fortress</span></h1>
            </a>
          </div>
          
          <div >
            <nav id="navmenu" className={`navmenu ${isMenuActive ? 'active' : ''}`}>
              <ul>
                <li><a href="/home" >Home</a></li>
                <li className="dropdown">
                  <a href="/about">
                    <span>About</span> <i className="bi bi-chevron-down toggle-dropdown"></i>
                  </a>
                  <ul>
                    <li><a href="/about#faq">FAQs</a></li>
                    <li><a href="/about#contact">Contact</a></li>
                  </ul>
                </li>
                <li><a href="/packages-registration">Packages/Registration</a></li>
                <li className="dropdown">
                  <a href="/members">
                    <span>Member</span> <i className="bi bi-chevron-down toggle-dropdown"></i>
                  </a>
                  <ul>
                    <li><a href="/members">Testimonial</a></li>
                  </ul>
                </li>
                <li>
                  {userData ? (
                    <a href="#" onClick={handleAccountClick}>
                      <i className="bi bi-person-circle me-2 mr-3"></i> Account
                    </a>
                  ) : (
                    <a href="#" onClick={(e) => { e.preventDefault(); navigate('/login'); }} id="buttonLogin">
                      Sign up/Log in
                    </a>
                  )}
                </li>
              </ul>
            </nav>
          </div>
          
          <div className={`hamburger mobile-nav-toggle ${isHamburgerActive ? 'active' : ''}`} onClick={toggleHamburger}>
            <i className="hamburger-button" class="fa fa-bars"></i>


            
          </div>
        </div>
      </header>

      <ConfirmModal
        show={showLogoutModal}
        handleClose={() => setShowLogoutModal(false)}
        title="Confirm Logout"
        message="Are you sure you want to logout?"
        handleConfirm={handleLogout}
      />
    </>
  );
}
