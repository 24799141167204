import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ErrorMessageModal = ({ show, handleClose, title, message }) => {
  return (
    <Modal  show={show} onHide={handleClose} centered>
      <Modal.Header >
      <Modal.Title className='error-modal-title'>{title}</Modal.Title>
        <button type="button" className="close" aria-label="Close" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body className='error-modal-message'>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorMessageModal;
