import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config'; 
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useNavigate } from 'react-router-dom';
import Loader from "../pages/Loader"

import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Members({ userData, clearUserData }) {

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  useEffect(() => {
      AOS.refresh();
  });

  const [members, setMembers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'members'));
        const memberData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setMembers(memberData);
      } catch (error) {
        console.error('Error fetching member data: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, []);

  const handleTestimonialClick = (id) => {
    navigate(`/testimonial/${id}`);
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500); 

    return () => clearTimeout(timer);
  }, []); 

  useEffect(() => {
    const hideTimer = setTimeout(() => {
      const preloader = document.getElementById('preloader');
      if (preloader) {
        preloader.classList.add('hidden');
      }
    }, 1000);

    return () => clearTimeout(hideTimer);
  }, []); 
  return (
    <>
    {loading && <Loader />}
      <Header userData={userData} clearUserData={clearUserData} />
      <div className='.section-members'>
        <div className="member-container main-div-container" data-aos="fade-up" data-aos-delay="100">
          <h1 className="section-title">The <span>Members</span></h1>
        </div>

        <div className="main-div-container">
          <div id="card-area">
            <div className="wrapper">
              <div className="member-testimonial-box box-area">
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  members.map((member) => (
                    <div className="individual-container box" key={member.id} data-aos="fade-up" data-aos-delay="500">
                      <img alt="" src={member.image || './img/temp-img.jpg'} />
                      <div className="overlay">
                        <h2>{member.name}</h2>
                        {member.titles && member.titles.split(',').map((title, index) => (
                          <p key={index} className="member-title">{title.trim()}</p>
                        ))}
                        {member.testimonials && (
                          <a href="#" onClick={() => handleTestimonialClick(member.id)}>
                            Testimonial
                          </a>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>

      </div>
      
      <Footer />
    </>
  );
}
