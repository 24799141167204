import React, { useState, useEffect } from 'react';
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../config";

const MultiStepProgressBar = ({ steps, initialStep = 1, isClientMode = false, userId, type }) => {
  const [current, setCurrent] = useState(initialStep);

  useEffect(() => {
    setProgressBar(current);
  }, [current, steps.length]);

  useEffect(() => {
    console.log(`Received userId: ${userId}`);
  }, [userId]);

  const setProgressBar = (currentStep) => {
    const percent = parseFloat(100 / steps.length) * currentStep;
    document.querySelector('.multi-step-progress-bar').style.width = `${percent}%`;
  };

  const updateProgress = async (currentStep) => {
    console.log(`Updating progress for userId: ${userId}`);
    if (userId) {
      const traineeDocRef = doc(db, 'registered_trainees', userId);
      const fieldToUpdate = type === 'assessment' ? 'assessment_took' : 'session_took';
      await updateDoc(traineeDocRef, {
        [fieldToUpdate]: currentStep
      });
    }
  };

  const nextStep = () => {
    // if (current < steps.length) {
    //   const newStep = current + 1;
    //   setCurrent(newStep);
    //   updateProgress(newStep);
    // }
    const newStep = current + 1;
    setCurrent(newStep);
    updateProgress(newStep);
  };

  const previousStep = () => {
    if (current > 1) {
      const newStep = current - 1;
      setCurrent(newStep);
      updateProgress(newStep);
    }
  };

  return (
    <div className="multi-step-controlled container">
      <div className="row justify-content-center">
        <div className="multi-step multi-step-main-container">
          <div className="px-0 pt-4 pb-0 mt-3 mb-3">
            <form id="multi-step-form">
              <ul id="multi-step-progressbar">
                {steps.map((step, index) => (
                  <li key={index} className={current > index ? 'active' : ''} data-step={index + 1}>
                    <strong>{step}</strong>
                  </li>
                ))}
              </ul>
              <div className="multi-step-progress-controlled" id="multi-step-progress-controlled">
                <div className="multi-step-progress-bar"></div>
              </div>
              <br />
              {steps.map((step, index) => (
                current === index + 1 && (
                  <fieldset key={index}>
                    <h2>{index === 0 ? "NO SESSION FINISHED" : `SESSION ${index} FINISHED`}</h2>
                    {!isClientMode && index < steps.length - 1 && (
                      <input
                        type="button"
                        name="next-step"
                        className="multi-step-next-step"
                        value="Next Step"
                        onClick={nextStep}
                      />
                    )}
                    {!isClientMode && index === steps.length - 1 && (
                      <input
                        type="button"
                        name="next-step"
                        className="multi-step-next-step"
                        value="Final Step"
                        onClick={nextStep}
                      />
                    )}
                    {!isClientMode && index > 0 && (
                      <input
                        type="button"
                        name="previous-step"
                        className="multi-step-previous-step"
                        value="Previous Step"
                        onClick={previousStep}
                      />
                    )}
                  </fieldset>
                )
              ))}
              {current === steps.length + 1 && (
                <fieldset>
                  <div className="multi-step-finish">
                    <h2 className="multi-step-text text-center">
                      <strong>ALL SESSIONS FINISHED</strong>
                    </h2>
                  </div>
                  {!isClientMode && (
                    <input
                      type="button"
                      name="previous-step"
                      className="multi-step-previous-step"
                      value="Previous Step"
                      onClick={previousStep}
                    />
                  )}
                </fieldset>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiStepProgressBar;
