import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc, setDoc, addDoc, query, where} from 'firebase/firestore';
import { db } from '../../config';
import ConfirmModal from '../../components/ConfirmModal';
import UpdatePaymentStatusModal from '../../components/UpdatePaymentStatusModal';
import ImageModal from '../../components/ImageModal'; 

export default function PendingRegistrationPayments() {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    const [currentItem, setCurrentItem] = useState(null);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false); 
    const [confirmMessage, setConfirmMessage] = useState('');
    const [confirmAction, setConfirmAction] = useState(() => () => {});
    const [imageModalUrl, setImageModalUrl] = useState(''); 

    useEffect(() => {
        retrieveData();
    }, []);

    useEffect(() => {
        if (searchQuery) {
            const filtered = data.filter(item =>
                item.userId.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(data);
        }
    }, [searchQuery, data]);

    const retrieveData = async () => {
        try {
            const paymentsQuery = query(
                collection(db, 'for_confirmation_payment'),
                where('status', '!=', 'confirmed')
            );
            
            const paymentsSnapshot = await getDocs(paymentsQuery);
            const usersSnapshot = await getDocs(collection(db, 'users'));
            const usersData = usersSnapshot.docs.reduce((acc, doc) => {
                acc[doc.id] = { id: doc.id, ...doc.data() };
                return acc;
            }, {});
    
            const paymentsData = paymentsSnapshot.docs.map(doc => {
                const paymentData = { id: doc.id, ...doc.data() };
                const userData = usersData[paymentData.userId] || {};
                return { ...paymentData, user: userData };
            });
    
            paymentsData.sort((a, b) => {
                const dateA = new Date(a.timestamp.seconds * 1000);
                const dateB = new Date(b.timestamp.seconds * 1000);
                return dateB - dateA;
            });
    
            setData(paymentsData);
            setFilteredData(paymentsData);
        } catch (error) {
            console.error('Error retrieving documents: ', error);
        }
    };
    

    const handleUpdateShow = (item) => {
        setCurrentItem(item);
        setShowUpdateModal(true);
    };

    const handleUpdateClose = () => {
        setCurrentItem(null);
        setShowUpdateModal(false);
    };

    const handleUpdate = async (updatedItem) => {
        console.log(updatedItem);
        try {
            const docRef = doc(db, 'for_confirmation_payment', updatedItem.id);
            console.log(updatedItem.id);
            console.log(updatedItem)
            await updateDoc(docRef, {
                status: 'confirmed'
            });
            const traineeData = {
                userId: updatedItem.userId, 
                confirmationPaymentId: updatedItem.id,
                session_took: 0,
                assessment_took: 0
            };
            const user_current_package_update = {
                currentPackage: updatedItem.package_title
            }
            await updateDoc(doc(db, 'users', updatedItem.userId), user_current_package_update);
            await setDoc(doc(db, 'registered_trainees', updatedItem.id), traineeData);
            
            if (updatedItem.status === 'confirmed') {
                const paymentHistoryData = {
                    ...updatedItem,
                    confirmedAt: new Date().toISOString() 
                };
                await addDoc(collection(db, 'payment_history'), paymentHistoryData);
                console.log("Added new entry to payment_history.");
            }

            
            retrieveData();
        } catch (error) {
            console.error('Error updating document: ', error);
        } finally {
            handleUpdateClose();
        }
    };

    const handleDelete = (id) => {
        setConfirmMessage('Deleting the payment would make the user resubmit his payment again.  Are you sure you want to delete this payment?');
        setConfirmAction(() => () => confirmDelete(id));
        setShowConfirmModal(true);
    };

    const confirmDelete = async (id) => {
        setShowConfirmModal(false);
        try {

            const userRef = doc(db, 'users', id);
            await updateDoc(userRef, {selected_package: "", registration_step: 2});

            await deleteDoc(doc(db, 'for_confirmation_payment', id));
            retrieveData();
        } catch (error) {
            console.error('Error deleting document: ', error);
        }
    };

    const handleReject = (id) => {
        setConfirmMessage('Are you sure you want to reject this payment?');
        setConfirmAction(() => () => confirmReject(id));
        setShowConfirmModal(true);
    };

    const confirmReject = async (id) => {
        setShowConfirmModal(false);
        try {
            const docRef = doc(db, 'for_confirmation_payment', id);
            await updateDoc(docRef, {
                status: 'rejected'
            });

            const userRef = doc(db, 'users', id);
            await updateDoc(userRef, {selected_package: "", registration_step: 1});

            retrieveData();
        } catch (error) {
            console.error('Error updating document: ', error);
        }
    };

    const formatTimestamp = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            const date = new Date(timestamp.seconds * 1000);
            return date.toLocaleString();
        }
        return '';
    };

    const handleImageClick = (imageUrl) => {
        setImageModalUrl(imageUrl);
        setShowImageModal(true);
    };

    return (
        <>
            <div className="headertitle">
                <h1>Pending Confirmation for Registration Payments</h1>
            </div>

            <hr className="my-4 main-hr" />
            <div className='scrollable-table'>

          
            <div className="container-fluid mt-5">
                <div className="mb-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search by user ID..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className="table-responsive">
                    <table className="table table-bordered table-hover accomplish-table-custom text-center">
                        <thead className="thead-dark">
                            <tr>
                                <th>No.</th>
                                <th>User ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Image</th>
                                <th>Timestamp</th>
                                <th>Payment for Package</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.length > 0 ? filteredData.map((item, index) => (
                                <tr key={item.id}>
                                    <td>{index + 1}</td>
                                    <td className="accomplish-data-style">{item.userId}</td>
                                    <td className="accomplish-data-style">{item.user.name}</td>
                                    <td className="accomplish-data-style">{item.user.email}</td>
                                    <td>
                                        <img
                                            src={item.imageUrl}
                                            alt="content"
                                            className="img-fluid"
                                            style={{ width: '100px', height: 'auto', display: 'block', margin: 'auto', cursor: 'pointer'}}
                                        />
                                    </td>
                                    <td className="accomplish-data-style">{formatTimestamp(item.timestamp)}</td>
                                    <td className="accomplish-data-style">{item.package_title}</td>
                                    <td className="accomplish-data-style">{item.status}</td>
                                    <td>
                                        <button className="m-2 btn btn-primary btn-sm mb-1" onClick={() => handleImageClick(item.imageUrl)}>View</button>
                                        <button className="m-2 btn btn-warning btn-sm me-2 mb-1" onClick={() => handleUpdateShow(item)}>Confirm</button>
                                        <button className="m-2 btn btn-danger btn-sm mb-1" onClick={() => handleDelete(item.id)}>Delete</button>
                                        <button className="m-2 btn btn-secondary btn-sm mb-1" onClick={() => handleReject(item.id)}>Reject</button>
                                    </td>
                                </tr>
                                
                            )): (
                                <tr>
                                    <td colSpan="8">No pending payment available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            <ConfirmModal
                show={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                title="Confirm Action"
                message={confirmMessage}
                handleConfirm={confirmAction}
            />
            {showUpdateModal && (
                <UpdatePaymentStatusModal
                    show={showUpdateModal}
                    handleClose={handleUpdateClose}
                    handleSave={handleUpdate}
                    item={currentItem}
                />
            )}
            {showImageModal && (
                <ImageModal
                    show={showImageModal}
                    handleClose={() => setShowImageModal(false)}
                    imageUrl={imageModalUrl}
                />
            )}
        </>
    );
}
