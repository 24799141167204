import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../config"; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function PaymentHistory() {
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [totalPayment, setTotalPayment] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShow(true);
  };

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'payment_history'));
        
        const payments = await Promise.all(querySnapshot.docs.map(async (paymentDoc) => {
          const paymentData = paymentDoc.data();
          
          const userDocRef = doc(db, 'users', paymentData.userId);
          const userDoc = await getDoc(userDocRef);
          const userName = userDoc.exists() ? userDoc.data().name : 'Unknown User';

          const packageDocRef = doc(db, 'packages', 'K9VgkoMsGmu4Zu3wztv9'); 
          const packageDoc = await getDoc(packageDocRef);
          const packagePrice = packageDoc.exists() && packageDoc.data()[paymentData.package_title]
            ? parseFloat(packageDoc.data()[paymentData.package_title][2].replace(/,/g, ''))
            : 0;

          return {
            id: paymentDoc.id,
            name: userName,
            packagePrice: packagePrice,
            ...paymentData
          };
        }));

        const total = payments.reduce((acc, item) => acc + item.packagePrice, 0);
        setTotalPayment(total);

        setPaymentHistory(payments);
      } catch (error) {
        console.error("Error fetching payment history: ", error);
      }
    };

    fetchPaymentHistory();
  }, []);

  return (
    <>
      <div className="headertitle">
        <h1>Payment History</h1>
        <h2 style={{color: 'black'}}>Total Accumulated Payments: PHP {totalPayment.toLocaleString()} </h2>
      </div>
      <hr className="my-2 main-hr" />

      <div className='scrollable-table-paymenthistory'>
        <div className="table-responsive">
          <table className="table table-bordered table-hover accomplish-table-custom text-center">
            <thead className="thead-dark">
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Package Title</th>
                <th>Package Price</th>
                <th>Payment Receipt Image</th>
                <th>Confirmed At</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paymentHistory.length > 0 ? paymentHistory.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td className="trainees-data-style">{item.name}</td>
                  <td className="trainees-data-style">{item.package_title}</td>
                  <td className="trainees-data-style">{item.packagePrice.toLocaleString()}</td>
                  <td>
                    {item.imageUrl ? (
                      <img
                        src={item.imageUrl}
                        alt="payment image"
                        className="img-fluid"
                        style={{ width: '100px', height: 'auto', display: 'block', margin: 'auto', cursor: 'pointer'}}
                        onClick={() => handleShow(item.imageUrl)}
                      />
                    ) : (
                      "No Image"
                    )}
                  </td>
                  <td className="trainees-data-style">
                    {item.confirmedAt ? (
                      new Date(item.confirmedAt).toLocaleDateString()
                    ) : (
                      "Date not available"
                    )}
                  </td>
                  <td className="trainees-data-style">{item.status}</td>
                  <td>
                    <button className="btn btn-primary btn-sm" onClick={() => handleShow(item.imageUrl)}>
                      View
                    </button>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan="8">No payment history available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Receipt Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedImage} alt="Payment receipt" className="img-fluid" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
