import React, { useEffect, useState, useRef } from 'react';
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../config"; 
import Button from 'react-bootstrap/Button';
import { Nav, Tab, Row, Col } from 'react-bootstrap';

export default function GenerateReports() {
  const [activeTab, setActiveTab] = useState('payment_history');
  const [data, setData] = useState([]);
  const reportRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (activeTab === 'payment_history') {
          const querySnapshot = await getDocs(collection(db, 'payment_history'));
          const payments = await Promise.all(querySnapshot.docs.map(async (paymentDoc) => {
            const paymentData = paymentDoc.data();
            
            const userDocRef = doc(db, 'users', paymentData.userId);
            const userDoc = await getDoc(userDocRef);
            const userName = userDoc.exists() ? userDoc.data().name : 'Unknown User';

            const packageDocRef = doc(db, 'packages', 'K9VgkoMsGmu4Zu3wztv9');
            const packageDoc = await getDoc(packageDocRef);
            const packagePrice = packageDoc.exists() && packageDoc.data()[paymentData.package_title]
              ? parseFloat(packageDoc.data()[paymentData.package_title][2].replace(/,/g, ''))
              : 0;

            return {
              id: paymentDoc.id,
              name: userName,
              packagePrice: packagePrice,
              ...paymentData
            };
          }));
          setData(payments);
        } else if (activeTab === 'registered_trainees') {
          const querySnapshot = await getDocs(collection(db, 'registered_trainees'));
          const trainees = await Promise.all(querySnapshot.docs.map(async (traineeDoc) => {
            const traineeData = traineeDoc.data();
            
            const userDocRef = doc(db, 'users', traineeData.userId);
            const userDoc = await getDoc(userDocRef);
            const userName = userDoc.exists() ? userDoc.data().name : 'Unknown User';

            return {
              id: traineeDoc.id,
              name: userName,
              ...traineeData
            };
          }));
          setData(trainees);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [activeTab]);

  const printReport = () => {
    window.print();
  };

  return (
    <>
      <div className="headertitle">
        <h1>Generate Reports</h1>
      </div>
      <hr className="my-2 main-hr" />

      <Tab.Container activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
        <Row>
          <Col sm={4}>
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link 
                  eventKey="payment_history" 
                  style={{ 
                    backgroundColor: activeTab === 'payment_history' ? '#007bff' : '#d3d3d3', 
                    color: activeTab === 'payment_history' ? 'white' : 'black', 
                    borderRadius: '5px',
                    marginRight: '10px'
                  }}>
                  Payment History
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link 
                  eventKey="registered_trainees" 
                  style={{ 
                    backgroundColor: activeTab === 'registered_trainees' ? '#007bff' : '#d3d3d3', 
                    color: activeTab === 'registered_trainees' ? 'white' : 'black', 
                    borderRadius: '5px',
                    marginRight: '10px'
                  }}>
                  Registered Trainees
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="payment_history">
                <div ref={reportRef}>
                  <h2 style={{ color: 'black', marginTop: '16px', marginBottom: '16px' }}>Payment History Report</h2>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>ID</th>
                        <th style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>Name</th>
                        <th style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>Package Title</th>
                        <th style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>Package Price</th>
                        <th style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>Status</th>
                        <th style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>Timestamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? data.map((item) => (
                        <tr key={item.id}>
                          <td style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>{item.id}</td>
                          <td style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>{item.name}</td>
                          <td style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>{item.package_title}</td>
                          <td style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>
                            {item.packagePrice ? item.packagePrice.toLocaleString() : "No Price"}
                          </td>
                          <td style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>{item.status}</td>
                          <td style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>
                            {item.timestamp ? new Date(item.timestamp.seconds * 1000).toLocaleDateString() : "No Date"}
                          </td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan="6" style={{ border: '1px solid #ddd', padding: '8px' }}>No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <p style={{ color: 'black', marginTop: '16px', marginBottom: '16px' }}>
                    Total Accumulated Earnings: PHP {data.reduce((acc, item) => acc + (item.packagePrice || 0), 0).toLocaleString()}
                  </p>
                  <p style={{ color: 'black', marginTop: '16px', marginBottom: '16px' }}>Total Clients: {data.length}</p>
                  <p style={{ color: 'black', marginTop: '16px', marginBottom: '16px' }}>Report Generated at {new Date().toLocaleString()}</p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="registered_trainees">
                <div ref={reportRef}>
                  <h2 style={{ color: 'black', marginTop: '16px', marginBottom: '16px' }}>Registered Trainees Report</h2>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>User ID</th>
                        <th style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>Name</th>
                        <th style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>Q & A / COMMUNICATION COACHING SESSION TOOK</th>
                        <th style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>ASSESSMENT TOOK</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? data.map((item) => (
                        <tr key={item.id}>
                          <td style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>{item.userId}</td>
                          <td style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>{item.name}</td>
                          <td style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>{item.session_took}</td>
                          <td style={{ color: 'black', border: '1px solid #ddd', padding: '8px' }}>{item.assessment_took}</td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan="5" style={{ border: '1px solid #ddd', padding: '8px' }}>No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <p style={{ color: 'black', marginTop: '16px', marginBottom: '16px' }}>Total Trainees: {data.length}</p>
                  <p style={{ color: 'black', marginTop: '16px', marginBottom: '16px' }}>Report Generated at {new Date().toLocaleString()}</p>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

      <Button variant="primary" onClick={printReport}>
        Print {activeTab === 'payment_history' ? 'Payment History' : 'Registered Trainees'}
      </Button>
    </>
  );
}
