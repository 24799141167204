import React, { useState, useEffect } from 'react';

const AddWinsModal = ({ show, handleClose, handleSave }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [date, setDate] = useState('');
  const [postUrl, setPostUrl] = useState('');

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      title,
      content,
      image,
      date,
      postUrl,
    };
    handleSave(formData);
    handleClose();
  };

  useEffect(() => {
    if (show) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [show]);

  const resetForm = () => {
    setTitle('');
    setContent('');
    setImage(null);
    setDate('');
    setPostUrl('');
  };

  useEffect(() => {
    if (!show) {
      resetForm();
    }
  }, [show]);

  return (
    <>
      {show && <div className="modal-backdrop fade show"></div>}
      <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="addNewsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="newsmodal-title modal-title" id="addNewsModalLabel">Add Wins Form</h5>
              <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="newsmodal-control" id="contactForm" name="contact" role="form" onSubmit={handleSubmit}>
                <div className="newsmodal-form-group">
                  <label className="newsmodal-label" htmlFor="formTitle">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="formTitle"
                    placeholder="Enter title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>
                <br />
                <div className="newsmodal-form-group">
                  <label className="newsmodal-label" htmlFor="formContent">Content</label>
                  <textarea
                    className="form-control"
                    id="formContent"
                    rows="5"
                    placeholder="Enter content"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    required
                  ></textarea>
                </div>
                <br />
                <div className="newsmodal-form-group">
                  <label className="newsmodal-label" htmlFor="formImage">Upload Image</label>
                  <input
                    type="file"
                    className="form-control"
                    id="formImage"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                </div>
                <br />
                <div className="newsmodal-form-group">
                  <label className="newsmodal-label" htmlFor="formDate">Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="formDate"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    required
                  />
                </div>
                <br />
                <div className="newsmodal-form-group">
                  <label className="newsmodal-label" htmlFor="formPostUrl">Post URL</label>
                  <input
                    type="url"
                    className="form-control"
                    id="formPostUrl"
                    placeholder="Enter post URL"
                    value={postUrl}
                    onChange={(e) => setPostUrl(e.target.value)}
                    required
                  />
                </div>
                <br />
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={handleClose}>
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWinsModal;
