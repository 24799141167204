import React from 'react';

const UploadProgressModal = ({ show, progress, success, handleClose }) => {
  return (
    <>
      {show && <div className="modal-backdrop fade show"></div>}
      <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="uploadProgressModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="uploadProgressModalLabel"><font style={{ color: '#161616' }}>Uploading Process</font></h5>
              <button type="button" className="close" aria-label="Close" onClick={handleClose} disabled={!success}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {!success ? (
                <>
                  <p><font style={{ color: '#161616' }}>Uploading... {progress}%</font></p>
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </>
              ) : (
                <p><font style={{ color: '#161616' }}>Upload Successful!</font></p>
              )}
            </div>
            <div className="modal-footer">
              {success && (
                <button type="button" className="btn btn-primary" onClick={handleClose}>
                  OK
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadProgressModal;
