import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function SplashPage() {
    const [isVisible, setIsVisible] = useState(true);
    const navigate = useNavigate();
    
    // useEffect(() => {
    //     const userVisitedStorage = localStorage.getItem("userVisited");
    //     if (userVisitedStorage) {
    //         navigate('/home');
    //     } else {
    //         localStorage.setItem("userVisited", JSON.stringify({ visited: 'yes' }));
    //     }
    // }, [navigate]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            navigate('/home');
        }, 4000); 

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <>
        
            {isVisible && (
                <div id="splash-screen">
                    <div className="splash-content" >
                        <h2>"You are the light of the world. A city set on a hill that cannot be hidden."</h2> 
                        <p>- Matthew 5:14</p>
                    </div>
                </div>
            )}
        </>
    );
}