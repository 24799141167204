import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from './pages/Home';
import PackagesRegistration from './pages/PackagesRegistration';
import PageNotFound from "./pages/PageNotFound";
import SplashPage from "./pages/SplashPage";
import Login from "./pages/Login";
import AdminPanel from "./pages/AdminPanel";
import User from "./pages/Client";
import HelloWorld from "./pages/helloworld";
import About from "./pages/About";
import Testimonial from "./pages/Testimonial";
import Members from "./pages/Members";
import Wins from "./pages/Wins";
import WriteTestimonial from "./pages/WriteTestimonial";
import Registration from "./pages/Registration";
import News from "./pages/News";
import LearningMaterials from "./pages/LearningMaterials";

export default function App() {
  const [userData, setUserData] = useState(null);
  const [isPageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    const userDataStorage = JSON.parse(localStorage.getItem("userData"));
    if (userDataStorage) {
      setUserData(userDataStorage);
    }
    setPageLoaded(true);
  }, []);

  const clearUserData = () => {
    localStorage.removeItem("userData");
    setUserData(null);
  };

  if (!isPageLoaded) {
    return <div>Loading...</div>; 
  }

  return (
    <div className={`fade-in ${isPageLoaded ? 'active' : ''}`}>
      <BrowserRouter>
        <Routes>
          <Route path="/client" element={userData ? <User userData={userData} clearUserData={clearUserData} setUserData={setUserData} /> : <Navigate to="/login" />} />
          <Route path="/learningmaterials" element={userData ? <LearningMaterials userData={userData} clearUserData={clearUserData} /> : <Navigate to="/login" />} />
          
          
          <Route path="/admin/*" element={userData ? <AdminPanel userData={userData} clearUserData={clearUserData} /> : <Navigate to="/login" />} />
          <Route index element={<PublicElement><SplashPage/></PublicElement>} />
          <Route path="/home" element={<PublicElement><Home userData={userData} clearUserData={clearUserData} /></PublicElement>} />
          <Route path="/packages-registration" element={<PublicElement><PackagesRegistration userData={userData} clearUserData={clearUserData} setUserData={setUserData}/></PublicElement>} />
          <Route path="/members" element={<PublicElement><Members userData={userData} clearUserData={clearUserData} /></PublicElement>} />
          
          <Route path="/wins/:id" element={<PublicElement><Wins userData={userData} clearUserData={clearUserData} /></PublicElement>} />
          <Route path="/news/:id" element={<PublicElement><News userData={userData} clearUserData={clearUserData} /></PublicElement>} />
          
          
          <Route path="/login" element={<Login setUserData={setUserData} />} />
          <Route path="/about" element={<PublicElement><About userData={userData} clearUserData={clearUserData} /></PublicElement>} />
          <Route path="/testimonial/:id" element={<PublicElement><Testimonial userData={userData} clearUserData={clearUserData} /></PublicElement>} />
          <Route path="/writetestimonial/:id" element={<PublicElement><WriteTestimonial userData={userData} clearUserData={clearUserData} /></PublicElement>} />
          
          

          <Route path="/registration/:packagesid" element={<PublicElement><Registration userData={userData} clearUserData={clearUserData} /></PublicElement>} />
          <Route path="/helloworld" element={<HelloWorld />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function PublicElement({ children }) {
  return <>{children}</>;
}
