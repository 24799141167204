import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import Footer from "../components/Footer";
import Header from "../components/Header";

import { db } from "../config"; 
import { collection, addDoc } from 'firebase/firestore'; 
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; 

export default function WriteTestimonial({ userData, clearUserData }) {
    const { id } = useParams();
    const navigate = useNavigate(); // Initialize useNavigate

    const [titles, setTitles] = useState([]);
    const [newTitle, setNewTitle] = useState('');
    const [caption, setCaption] = useState('');
    const [image, setImage] = useState(null); 
    const [loading, setLoading] = useState(false); 

    const handleAddTitle = () => {
        if (titles.length < 6 && newTitle.trim() !== '') {
            setTitles([...titles, newTitle]);
            setNewTitle('');
        }
    };

    const handleSubmitTestimonial = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Format the date to YYYY-MM-DD
            const formattedDate = new Date().toISOString().slice(0, 10);

            let imageUrl = '';
            if (image) {
                // Upload the image to Firebase Storage and get the download URL
                const storage = getStorage();
                const storageRef = ref(storage, `testimonials/${id}/${image.name}`);
                await uploadBytes(storageRef, image);
                imageUrl = await getDownloadURL(storageRef);
            }

            const testimonialData = {
                date: formattedDate, 
                image: imageUrl, // The download URL from Firebase Storage
                name: (userData.name || 'Anonymous').toUpperCase(), // Convert name to uppercase
                testimonials: caption,
                titles: titles.join(', '), 
            };

            await addDoc(collection(db, 'members'), testimonialData);
            alert('Testimonial submitted successfully!');
            navigate('/client'); // Redirect to /client after successful submission
        } catch (error) {
            console.error('Error submitting testimonial:', error);
            alert('There was an error submitting your testimonial. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    return (
        <>
            <Header userData={userData} clearUserData={clearUserData} />
            <div className='main-div-container fade-in'>
                <div className='write-testimonial-pic-container'>
                    <div className="avatar-preview" style={{ backgroundImage: `url(${userData.image || '../../img/temp-img.jpg'})` }}></div>    
                </div>

                <div className="head">
                    <p className="write-testimonial-header">Write <span>Testimonial</span></p>
                </div>

                <div className="d-block">
                    <div className="write-testimonial-form-columns form-columns">
                        <div className="write-testimonial-form-left">
                            <form className='write-testimonial-form' onSubmit={handleSubmitTestimonial}>
                                <div className="write-testimonial-add-titles-container">
                                    <h3>Titles (Max: 6)</h3>
                                    {titles.map((title, index) => (
                                        <div key={index} className='write-testimonial-title-input'>
                                            <label htmlFor={`title${index + 1}`}>Title {index + 1}</label>
                                            <input
                                                type="text"
                                                name={`title${index + 1}`}
                                                id={`title${index + 1}`}
                                                className="write-testimonial-form-control form-control"
                                                value={title}
                                                onChange={(e) => {
                                                    const newTitles = [...titles];
                                                    newTitles[index] = e.target.value;
                                                    setTitles(newTitles);
                                                }}
                                            />
                                        </div>
                                    ))}
                                    {titles.length < 6 && (
                                        <div className=" form-group">
                                            <label htmlFor="newTitle">New Title</label>
                                            <input
                                                type="text"
                                                id="newTitle"
                                                className="write-testimonial-form-control form-control"
                                                value={newTitle}
                                                onChange={(e) => setNewTitle(e.target.value)}
                                            />
                                            <button type="button" className="btn btn-primary mt-3 write-testimonial-button" onClick={handleAddTitle}>Add</button>
                                        </div>
                                    )}
                                </div>

                                <div className="write-testimonial-add-titles-container">
                                    <h3>Caption</h3>
                                    <div className="form-group">
                                        <textarea
                                            id="caption"
                                            name="caption"
                                            className="write-testimonial-form-control form-control"
                                            value={caption}
                                            onChange={(e) => setCaption(e.target.value)}
                                            rows={5}
                                            placeholder="Write your testimonial..."
                                            required
                                        />
                                    </div>
                                </div>

                                <div className='write-testimonial-final-button-container '>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary write-testimonial-button write-testimonial-final-button" disabled={loading}>
                                            {loading ? 'Submitting...' : 'Submit Testimonial'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="write-testimonial-form-right">
                            <form className='write-testimonial-form'>
                                <div className="form-group file-area">
                                    <label htmlFor="images">Images <span>Your images should be at least 400x300 wide</span></label>
                                    <input 
                                        type="file" 
                                        name="images"
                                        id="images" 
                                        required
                                        multiple
                                        onChange={handleImageChange} 
                                    />
                                    <div className="file-dummy">
                                        <div className="success">Great, your files are selected. Keep on.</div>
                                        <div className="default">Please select some files</div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>    
            </div>
            <Footer />
        </>
    );
}
