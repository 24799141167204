import React from 'react';

const DashboardCard = ({ label, counter, style  }) => {
  return (
    <div className="col-md-6 mb-4">
      <div className="card-bg card shadow-sm " style={style}>
        <div className="counter-card card-body">
          <h5 className="p-2 card-title">{label}</h5>
          <h1 className="p-2 card-counter ">{counter}</h1>
          
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;