import React, { useState } from 'react';
import { Link, Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import ConfirmModal from '../components/ConfirmModal';

import Dashboard from '../pages/AdminPanels/Dashboard';
import Accomplishments from '../pages/AdminPanels/Accomplishments';
import News from '../pages/AdminPanels/News';

import Wins from '../pages/AdminPanels/Wins';

import Testimonials from '../pages/AdminPanels/Testimonials';
import Members from '../pages/AdminPanels/Members';
import Home from '../pages/Home';

import Trainees from './AdminPanels/Trainees';
import TraineeSession from '../pages/AdminPanels/TraineeSession'; 
import PendingRegistrationPayments from '../pages/AdminPanels/PendingRegistrationPayments'; 
import PaymentHistory from './AdminPanels/PaymentHistory';
import GenerateReports from './AdminPanels/GenerateReports';
import LearningMaterialsControl from './AdminPanels/LearningMaterialsControl';

export default function AdminPanel({ userData, clearUserData }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    clearUserData();
    setShowLogoutModal(false);
    navigate('/login'); 
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <nav className="navbar navbar-dark bg-dark d-lg-none">
        <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <a className="navbar-brand" href="#">Admin Dashboard</a>
      </nav>
      
      <div className={`layout-flex ${sidebarOpen ? 'sidebar-open' : ''}`}>
        <div className={`contentsidebar ${sidebarOpen ? 'open' : ''}`}>
          <div className="sidebar admin-display-flex  d-flex flex-column vh-100 bg-dark">
            <div className="header-sidebar">
              <div className="header-container">
                <p className="brand medium" href="#"> The <font style={{ color: '#3AB7FA' }}>Fortress</font></p>
              </div>
            </div>
            <div className="titlecontainer p-4">
              <p className="medium">ADMIN PANEL</p>
              
            </div>
            
            <div className="sidebarnavs admin-display-flex d-flex">
              <nav className="nav flex-column flex-grow-1">
                <Link to="/admin/dashboard" className="nav-link text-white">
                  <i className="fas fa-tachometer-alt"></i> Dashboard
                </Link>
                {/* <Link to="/admin/accomplishments" className="nav-link text-white">
                  <i className="fas fa-trophy"></i> Accomplishment
                </Link> */}
                <Link to="/admin/news" className="nav-link text-white">
                  <i className="fas fa-newspaper"></i> News
                </Link>
                <Link to="/admin/wins" className="nav-link text-white">
                  <i className="fas fa-trophy"></i> Wins
                </Link>
                {/* <Link to="/admin/testimonials" className="nav-link text-white">
                  <i className="fas fa-comments"></i> Testimonials
                </Link> */}
                <Link to="/admin/members" className="nav-link text-white">
                  <i className="fas fa-users"></i> Members Testimonials
                </Link>
                <Link to="/admin/trainees" className="nav-link text-white">
                  <i className="fas fa-user-graduate"></i> Registered Trainees
                </Link>
                <Link to="/admin/pendingregistrationpayments" className="nav-link text-white sm-modified">
                <i class="fas fa-hourglass-half"></i> Pending Registration Payments
                </Link>


                <Link to="/admin/paymenthistory" className="nav-link text-white sm-modified">
                <i class="fas fa-money-bill"></i> Payment History
                </Link>

                <Link to="/admin/learningmaterialscontrol" className="nav-link text-white sm-modified">
                <i class="fas fa-book"></i> Learning Materials
                </Link>

                <Link to="/admin/generatereports" className="nav-link text-white sm-modified">
                <i class="fas fa-database"></i> Generate Reports
                </Link>

                <div className="mt-auto">
                  <Link to="/home" className="nav-link text-white">
                    <i className="fas fa-globe"></i> Public Page
                  </Link>
                  <button className="nav-link text-white btn btn-link" onClick={() => setShowLogoutModal(true)}>
                    <i className="fas fa-sign-out-alt"></i> Logout
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className="main-content">
          <div className="content flex-grow-1 p-4">
            <Routes>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="accomplishments" element={<Accomplishments />} />
              <Route path="news" element={<News />} />

              <Route path="wins" element={<Wins />} />

              <Route path="learningmaterialscontrol" element={<LearningMaterialsControl />} />

              <Route path="testimonials" element={<Testimonials />} />

              <Route path="members" element={<Members />} />
              <Route path="trainees" element={<Trainees />} />
              <Route path="trainees/:id/sessions" element={<TraineeSession />} /> 

              <Route path="pendingregistrationpayments" element={<PendingRegistrationPayments />} /> 
              
              <Route path="paymenthistory" element={<PaymentHistory/>} />
              <Route path="generatereports" element={<GenerateReports/>} />

              <Route path="home" element={<Home />} />
              
              <Route path="/" element={<Navigate to="dashboard" />} />
            </Routes>
          </div>
        </div>

        <ConfirmModal
          show={showLogoutModal}
          handleClose={() => setShowLogoutModal(false)}
          title="Confirm Logout"
          message="Are you sure you want to logout?"
          handleConfirm={handleLogout}
        />
      </div>
    </>
  );
}
